<template>
  <svg width="140px" height="140px" viewBox="0 0 140 140">
    <title>User password not validated</title>

    <path
      d="M115.802 124C123.002 124 127.302 118.9 129.502 112.9C135.002 98.0998 131.902 80.3998 114.202 74.5998C94.002 68.1998 73.002 51.6998 65.902 45.2998C55.202 35.6998 45.902 30.9998 33.902 35.9998C23.502 40.2998 22.802 49.1998 27.702 57.1998C32.602 65.1998 41.002 69.4998 33.902 79.1998C29.202 85.6998 8.20202 90.7998 8.00202 105.2C7.90202 114.5 11.502 124 22.202 124H115.802Z"
      fill="#F3FBFE"
    />
    <path
      d="M54.202 65H57.002V58H54.202C53.002 58 52.002 59 52.002 60.2V62.7C52.002 64 53.002 65 54.202 65Z"
      fill="#CDEEFB"
    />
    <path
      d="M49.1023 97.3001H55.2023V84.6001H49.1023C45.7023 84.6001 42.9023 87.4001 42.9023 90.8001V91.1001C42.9023 94.5001 45.7023 97.3001 49.1023 97.3001Z"
      fill="#00A0D6"
    />
    <path d="M63.1541 124.033L84.9541 124.033L84.9541 116.333L63.1541 116.333L63.1541 124.033Z" fill="#CDEEFB" />
    <path opacity="0.5" d="M63.1541 124L84.9541 124L84.9541 116L63.1541 116L63.1541 124Z" fill="#48BAE4" />
    <path d="M63.002 124.033L79.002 124.033L79.002 116.333L63.002 116.333L63.002 124.033Z" fill="#CDEEFB" />
    <path
      opacity="0.5"
      d="M54.202 65H57.002V58H54.202C53.002 58 52.002 59 52.002 60.2V62.7C52.002 64 53.002 65 54.202 65Z"
      fill="#48BAE4"
    />
    <path
      d="M68.6023 72H94.2023C97.4023 72 99.9023 69.4 99.9023 66.3V56.7C99.9023 53.5 97.3023 51 94.2023 51H68.6023C65.4023 51 62.9023 53.6 62.9023 56.7V66.3C62.9023 69.4 65.5023 72 68.6023 72Z"
      fill="#CDEEFB"
    />
    <path
      d="M60.702 72H92.002V51H60.702C57.502 51 55.002 53.6 55.002 56.7V66.3C55.002 69.4 57.602 72 60.702 72Z"
      fill="#CDEEFB"
    />
    <path
      d="M92.7021 64.9998H96.7021C98.0021 64.9998 99.0022 63.9998 99.0022 62.6998V60.0998C99.0022 58.7998 98.0021 57.7998 96.7021 57.7998H92.7021V64.9998Z"
      fill="#CDEEFB"
    />
    <path
      d="M68.702 117H102.202C105.402 117 107.902 114.4 107.902 111.3V80.7C107.902 77.5 105.302 75 102.202 75H68.702C65.502 75 63.002 77.6 63.002 80.7V111.2C63.002 114.4 65.602 117 68.702 117Z"
      fill="#CDEEFB"
    />
    <path
      d="M53.702 117H93.002V75H53.702C50.502 75 48.002 77.6 48.002 80.7V111.2C48.002 114.4 50.602 117 53.702 117Z"
      fill="#CDEEFB"
    />
    <path
      opacity="0.5"
      d="M92.802 71.9999H94.302C97.502 71.9999 100.102 69.3999 100.102 66.1999V56.6999C100.102 53.4999 97.502 50.8999 94.302 50.8999H92.802C89.602 50.8999 87.002 53.4999 87.002 56.6999V66.1999C87.002 69.3999 89.602 71.9999 92.802 71.9999Z"
      fill="#48BAE4"
    />
    <path
      opacity="0.5"
      d="M92.802 117H102.202C105.402 117 108.002 114.4 108.002 111.2V80.8C108.002 77.6 105.402 75 102.202 75H92.802C89.602 75 87.002 77.6 87.002 80.8V111.2C87.002 114.4 89.602 117 92.802 117Z"
      fill="#48BAE4"
    />
    <path
      d="M56.602 98.9999H78.502C79.902 98.9999 81.102 97.7999 81.102 96.3999V86.4999C81.102 85.0999 79.902 83.8999 78.502 83.8999H56.602C55.202 83.8999 54.002 85.0999 54.002 86.4999V96.3999C54.002 97.7999 55.202 98.9999 56.602 98.9999Z"
      fill="#48BAE4"
    />
    <path
      opacity="0.5"
      d="M62.402 64.9999H79.702C82.1019 64.9999 84.102 62.9999 84.102 60.5999V60.2999C84.102 57.8999 82.1019 55.8999 79.702 55.8999H62.402C60.002 55.9999 58.002 57.9999 58.002 60.3999V60.6999C58.002 62.9999 60.002 64.9999 62.402 64.9999Z"
      fill="#48BAE4"
    />
    <path
      opacity="0.5"
      d="M79.602 56H62.402C60.002 56 58.002 58 58.002 60.4V60.7C58.002 61.2 58.102 61.6 58.202 62.1C58.802 60.4 60.402 59.1 62.302 59.1H79.602C81.502 59.1 83.1019 60.4 83.702 62.1C83.802 61.7 83.902 61.2 83.902 60.7V60.4C84.002 58 82.002 56 79.602 56Z"
      fill="#48BAE4"
    />
    <path
      d="M75.5023 62.9999C76.4023 62.9999 77.0023 62.2999 77.0023 61.4999V59.3999C77.0023 58.5999 76.3023 57.8999 75.5023 57.8999H75.4023C74.6023 57.8999 73.9023 58.5999 73.9023 59.3999V61.4999C74.0023 62.2999 74.7023 62.9999 75.5023 62.9999Z"
      fill="#2D2824"
    />
    <path
      d="M65.502 63C66.302 63 67.002 62.3 67.002 61.5V59.5C67.002 58.7 66.302 58 65.502 58C64.702 58 64.002 58.7 64.002 59.5V61.5C64.002 62.3 64.702 63 65.502 63Z"
      fill="#2D2824"
    />
    <path d="M47.002 62L53.002 62L53.002 61L47.002 61L47.002 62Z" fill="#CDEEFB" />
    <path opacity="0.5" d="M47.002 62L53.002 62L53.002 61L47.002 61L47.002 62Z" fill="#48BAE4" />
    <path
      d="M46.602 63.4998C47.7617 63.4998 48.702 62.5596 48.702 61.3998C48.702 60.24 47.7617 59.2998 46.602 59.2998C45.4422 59.2998 44.502 60.24 44.502 61.3998C44.502 62.5596 45.4422 63.4998 46.602 63.4998Z"
      fill="#CDEEFB"
    />
    <path
      opacity="0.5"
      d="M46.602 63.4998C47.7617 63.4998 48.702 62.5596 48.702 61.3998C48.702 60.24 47.7617 59.2998 46.602 59.2998C45.4422 59.2998 44.502 60.24 44.502 61.3998C44.502 62.5596 45.4422 63.4998 46.602 63.4998Z"
      fill="#48BAE4"
    />
    <path d="M105.002 61H99.002V62H105.002V61Z" fill="#CDEEFB" />
    <path d="M104.802 61H99.002V62H104.802V61Z" fill="#48BAE4" />
    <path
      d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
      fill="#CDEEFB"
    />
    <path
      d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
      fill="#CDEEFB"
    />
    <path
      d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
      fill="#CDEEFB"
    />
    <path
      d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
      fill="#CDEEFB"
    />
    <g opacity="0.8">
      <path
        opacity="0.8"
        d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
        fill="#48BAE4"
      />
      <path
        opacity="0.8"
        d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
        fill="#48BAE4"
      />
      <path
        opacity="0.8"
        d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
        fill="#48BAE4"
      />
      <path
        opacity="0.8"
        d="M104.802 63.4998C105.962 63.4998 106.902 62.5596 106.902 61.3998C106.902 60.24 105.962 59.2998 104.802 59.2998C103.642 59.2998 102.702 60.24 102.702 61.3998C102.702 62.5596 103.642 63.4998 104.802 63.4998Z"
        fill="#48BAE4"
      />
    </g>
    <path
      opacity="0.8"
      d="M94.002 65.0001H96.802C98.002 65.0001 99.002 64.0001 99.002 62.8001V60.3001C99.002 59.1001 98.002 58.1001 96.802 58.1001H94.002V65.0001Z"
      fill="#48BAE4"
    />
    <path
      d="M74.1016 59.7998C74.6538 59.7998 75.1016 59.3521 75.1016 58.7998C75.1016 58.2475 74.6538 57.7998 74.1016 57.7998C73.5493 57.7998 73.1016 58.2475 73.1016 58.7998C73.1016 59.3521 73.5493 59.7998 74.1016 59.7998Z"
      fill="white"
    />
    <path
      d="M64.1016 59.7998C64.6538 59.7998 65.1016 59.3521 65.1016 58.7998C65.1016 58.2475 64.6538 57.7998 64.1016 57.7998C63.5493 57.7998 63.1016 58.2475 63.1016 58.7998C63.1016 59.3521 63.5493 59.7998 64.1016 59.7998Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M65.3022 75C65.3022 75 65.4022 75 65.3022 75H75.7022C74.8022 75.1 74.1022 75.8 74.1022 76.7V78H67.2022H63.6022V76.8C63.5022 75.8 64.3022 75 65.3022 75Z"
      fill="#48BAE4"
    />
    <path
      d="M98.9022 97.5002H105.002V84.7002H98.9022C95.5022 84.7002 92.7021 87.5002 92.7021 90.9002V91.2002C92.7021 94.7002 95.5022 97.5002 98.9022 97.5002Z"
      fill="#00A0D6"
    />
    <path
      d="M104.902 97.5002C108.437 97.5002 111.302 94.6348 111.302 91.1002C111.302 87.5656 108.437 84.7002 104.902 84.7002C101.368 84.7002 98.5022 87.5656 98.5022 91.1002C98.5022 94.6348 101.368 97.5002 104.902 97.5002Z"
      fill="#48BAE4"
    />
    <path
      d="M114.502 100.6V96.7004C114.502 93.5004 111.902 90.9004 108.702 90.9004H104.902"
      stroke="#CDEEFB"
      strokeWidth="6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M109.802 119.8H119.102V103.6C119.102 101 117.002 98.9004 114.402 98.9004C111.802 98.9004 109.702 101 109.702 103.6V119.8H109.802Z"
      fill="#48BAE4"
    />
    <path
      d="M114.502 122.8C117.098 122.8 119.202 121.457 119.202 119.8C119.202 118.143 117.098 116.8 114.502 116.8C111.907 116.8 109.802 118.143 109.802 119.8C109.802 121.457 111.907 122.8 114.502 122.8Z"
      fill="#CDEEFB"
    />
    <path
      opacity="0.5"
      d="M114.502 122.8C117.098 122.8 119.202 121.457 119.202 119.8C119.202 118.143 117.098 116.8 114.502 116.8C111.907 116.8 109.802 118.143 109.802 119.8C109.802 121.457 111.907 122.8 114.502 122.8Z"
      fill="#48BAE4"
    />
    <path
      d="M114.502 120.9V125.7"
      stroke="#CDEEFB"
      strokeWidth="6"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M111.402 136.3C110.602 136.3 110.002 135.7 110.002 134.9V132H112.902V134.9C112.902 135.7 112.202 136.3 111.402 136.3Z"
      fill="#00A0D6"
    />
    <path
      d="M114.602 125C112.002 125 110.002 127.3 110.002 129.9V133.3H115.402C115.902 133.3 116.402 133.7 116.402 134.3V136.4C116.402 137.2 117.002 137.9 117.802 137.9C118.602 137.9 119.302 137.3 119.302 136.4V133.2V132.6V129.6C119.302 127.1 117.202 125 114.602 125Z"
      fill="#00A0D6"
    />
    <path
      d="M121.502 137.9C120.602 137.9 120.002 137.2 120.002 136.3V133.6C120.002 133.3 119.702 133 119.402 133H116.802C115.902 133 115.302 132.3 115.302 131.4C115.302 130.5 116.002 129.8 116.802 129.8H119.402C121.402 129.8 123.102 131.5 123.102 133.6V136.3C123.002 137.2 122.302 137.9 121.502 137.9Z"
      fill="#00A0D6"
    />
    <path
      d="M119.302 132V131.7C119.602 131.7 119.902 132 119.902 132.3V133.6C119.902 133.3 119.602 133 119.302 133V132Z"
      fill="#00A0D6"
    />
    <path
      d="M112.902 132H115.402C115.902 132 116.402 132.4 116.402 133V134.3C116.402 133.7 116.002 133.3 115.402 133.3H112.902V132Z"
      fill="#007DB1"
    />
    <path
      d="M74.502 89H60.502C60.202 89 60.002 88.8 60.002 88.5C60.002 88.2 60.202 88 60.502 88H74.602C74.902 88 75.102 88.2 75.102 88.5C75.002 88.8 74.802 89 74.502 89Z"
      fill="#00A0D6"
    />
    <path
      d="M71.502 91H59.502C59.202 91 59.002 90.8 59.002 90.5C59.002 90.2 59.202 90 59.502 90H71.502C71.802 90 72.002 90.2 72.002 90.5C72.002 90.8 71.802 91 71.502 91Z"
      fill="#00A0D6"
    />
    <path
      d="M69.502 93H59.502C59.202 93 59.002 92.8 59.002 92.5C59.002 92.2 59.202 92 59.502 92H69.502C69.802 92 70.002 92.2 70.002 92.5C70.002 92.8 69.802 93 69.502 93Z"
      fill="#00A0D6"
    />
    <path
      d="M72.502 93H71.502C71.202 93 71.002 92.8 71.002 92.5C71.002 92.2 71.202 92 71.502 92H72.502C72.802 92 73.002 92.2 73.002 92.5C73.002 92.8 72.802 93 72.502 93Z"
      fill="#00A0D6"
    />
    <path d="M43.7016 59.5L42.1016 58.5" stroke="#E36200" strokeMiterlimit="10" />
    <path d="M43.7016 59.5L42.1016 58.5" stroke="#E02D00" strokeMiterlimit="10" />
    <path d="M42.1016 64.1998L43.7016 63.2998" stroke="#E02D00" strokeMiterlimit="10" />
    <mask id="path-58-inside-1" fill="white">
      <path d="M42.7023 61H40.9023V62H42.7023V61Z" />
    </mask>
    <path d="M42.7023 61H40.9023V62H42.7023V61Z" fill="#E02D00" />
    <path
      d="M40.9023 61V60H39.9023V61H40.9023ZM42.7023 61H43.7023V60H42.7023V61ZM42.7023 62V63H43.7023V62H42.7023ZM40.9023 62H39.9023V63H40.9023V62ZM40.9023 62H42.7023V60H40.9023V62ZM41.7023 61V62H43.7023V61H41.7023ZM42.7023 61H40.9023V63H42.7023V61ZM41.9023 62V61H39.9023V62H41.9023Z"
      fill="#E02D00"
      mask="url(#path-58-inside-1)"
    />
    <path d="M107.802 59.5L109.402 58.5" stroke="#E36200" strokeMiterlimit="10" />
    <path d="M107.802 59.5L109.402 58.5" stroke="#E02D00" strokeMiterlimit="10" />
    <path d="M109.402 64.1998L107.802 63.2998" stroke="#E02D00" strokeMiterlimit="10" />
    <mask id="path-63-inside-2" fill="white">
      <path d="M110.502 61H108.702V62H110.502V61Z" />
    </mask>
    <path d="M110.502 61H108.702V62H110.502V61Z" fill="#E02D00" />
    <path
      d="M108.702 61V60H107.702V61H108.702ZM110.502 61H111.502V60H110.502V61ZM110.502 62V63H111.502V62H110.502ZM108.702 62H107.702V63H108.702V62ZM108.702 62H110.502V60H108.702V62ZM109.502 61V62H111.502V61H109.502ZM110.502 61H108.702V63H110.502V61ZM109.702 62V61H107.702V62H109.702Z"
      fill="#E02D00"
      mask="url(#path-63-inside-2)"
    />
    <path d="M59.002 124H66.002V90H59.002L59.002 124Z" fill="#1798CE" />
    <path d="M60.002 90H14.002V124H60.002V90Z" fill="#4ABBE3" />
    <path opacity="0.4" d="M60.002 90H14.002V124H60.002V90Z" fill="#00A0D6" />
    <path d="M37.002 106H8.00195V124H37.002V106Z" fill="#F0F0F0" />
    <path d="M34.002 108H30.002V112H34.002V108Z" fill="#E02D00" />
    <path d="M34.002 115H24.002V116H34.002V115Z" fill="#1DB0DE" />
    <path d="M32.002 117H24.002V118H32.002V117Z" fill="#1DB0DE" />
    <path d="M28.002 119H24.002V120H28.002V119Z" fill="#1DB0DE" />
    <path d="M31.002 119H29.002V120H31.002V119Z" fill="#1DB0DE" />
    <path
      d="M22.002 98.3H19.002V97.4L20.202 96.3C20.702 95.8 20.902 95.6 20.902 95.3C20.902 95 20.702 94.9 20.402 94.9C20.102 94.9 19.802 95 19.602 95.2H19.202V94.4C19.502 94.2 20.002 94 20.502 94C21.502 94 22.102 94.4 22.102 95.3C22.102 95.9 21.702 96.3 21.102 96.9L20.402 97.5H22.002V98.3Z"
      fill="#1798CE"
    />
    <path
      d="M24.7021 96.3001L25.7021 95.1001H26.6021V95.4001L25.7021 96.4001L26.7021 98.1001V98.4001H25.7021L25.0021 97.1001L24.7021 97.4001V98.4001H23.6021V94.1001H24.302C24.602 94.1001 24.7021 94.2001 24.7021 94.7001V96.3001Z"
      fill="#1798CE"
    />
    <path
      d="M30.0021 98.1001C30.0021 99.0001 29.3021 99.4001 28.2021 99.4001C27.6021 99.4001 27.2021 99.3001 26.9021 99.2001V98.5001H27.2021C27.4021 98.6001 27.7021 98.7001 28.0021 98.7001C28.6021 98.7001 28.802 98.5001 28.802 98.1001V97.9001C28.602 98.1001 28.302 98.2001 27.9021 98.2001C27.2021 98.2001 26.6021 97.8001 26.6021 96.7001C26.6021 95.5001 27.3021 95.1001 28.0021 95.1001C28.4021 95.1001 28.702 95.2001 28.9021 95.5001C29.002 95.3001 29.102 95.2001 29.302 95.2001H30.0021V98.1001ZM28.9021 97.3001V96.0001C28.8021 95.9001 28.6021 95.9001 28.5021 95.9001C28.2021 95.9001 27.9021 96.0001 27.9021 96.7001C27.9021 97.4001 28.1021 97.5001 28.5021 97.5001C28.6021 97.5001 28.8021 97.4001 28.9021 97.3001Z"
      fill="#1798CE"
    />
    <path d="M55.002 112H44.002V119H55.002V112Z" fill="white" />
    <path d="M53.002 114H46.002V115H53.002V114Z" fill="#1DB0DE" />
    <path d="M51.002 116H46.002V117H51.002V116Z" fill="#1DB0DE" />
    <path d="M30.002 100H19.002V101H30.002V100Z" fill="#1798CE" />
    <path d="M27.002 102H19.002V103H27.002V102Z" fill="#1798CE" />
    <path opacity="0.15" d="M65.502 90H68.902L73.002 94V124H65.502V90Z" fill="#1798CE" />
    <path
      d="M73.2021 31.9999L75.2021 28.8999H76.7021L78.8022 31.9999V32.4999H77.3022L76.0022 30.5999L74.7021 32.3999H73.2021V31.9999Z"
      fill="#CDEEFB"
    />
    <path
      d="M84.0022 29.6999C83.0022 29.9999 81.9022 31.0999 81.9022 33.8999C81.9022 36.8999 83.0022 37.8999 84.0022 38.2999V39.6999H83.6022C81.8022 39.2999 80.0022 37.6999 80.0022 33.9999C80.0022 30.3999 81.8022 28.7999 83.6022 28.3999H84.0022V29.6999Z"
      fill="#E36200"
    />
    <path
      d="M85.2021 38.4L88.5022 29H90.8022L94.1021 38.4V39H92.0022L91.2021 36.5H87.8022L87.0022 38.9H85.2021V38.4ZM88.3022 34.9H90.7021L89.5022 31.2L88.3022 34.9Z"
      fill="#CDEEFB"
    />
    <path
      d="M98.5022 34.8V37.5H96.8022V34.8H94.3022V33.2H96.8022V30.5H98.5022V33.2H101.002V34.8H98.5022Z"
      fill="#E02D00"
    />
    <path
      d="M101.702 28.3999H102.102C103.902 28.7999 105.702 30.3999 105.702 33.9999C105.702 37.6999 103.902 39.2999 102.102 39.6999H101.702V38.2999C102.702 37.9999 103.802 36.8999 103.802 33.8999C103.802 31.0999 102.702 30.0999 101.702 29.6999V28.3999Z"
      fill="#DBF0DF"
    />
    <path
      d="M109.502 30.6999L110.402 30.0999L111.002 29.8999L111.502 31.3999L110.902 31.5999H109.802L110.702 32.2999L111.102 32.7999L109.802 33.6999L109.402 33.1999L109.002 31.9999L108.602 33.0999L108.202 33.5999L106.902 32.6999L107.302 32.1999L108.202 31.4999H107.102L106.502 31.2999L107.002 29.8999L107.602 30.0999L108.502 30.6999L108.202 29.5999V28.8999H109.802V29.5999L109.502 30.6999Z"
      fill="#48BAE4"
    />
    <path
      d="M112.602 28.8999H116.002C118.402 28.8999 119.502 29.6999 119.502 31.4999C119.502 32.6999 118.802 33.2999 117.702 33.5999V33.6999C119.002 33.8999 119.802 34.5999 119.802 35.9999C119.802 37.8999 118.502 38.8999 116.002 38.8999H112.502V28.8999H112.602ZM116.002 32.9999C117.002 32.9999 117.402 32.5999 117.402 31.6999C117.402 30.8999 117.002 30.4999 116.002 30.4999H114.802V32.9999H116.002ZM116.102 37.3999C117.202 37.3999 117.702 36.7999 117.702 35.9999C117.702 35.0999 117.202 34.5999 116.102 34.5999H114.802V37.4999H116.102V37.3999Z"
      fill="#F0F0F0"
    />
    <path
      d="M46.002 16.9V31.9C46.002 32.4 46.402 32.8 46.902 32.8H59.902C60.302 32.8 60.602 33 60.802 33.2L63.202 36C63.502 36.3 64.002 36.1 64.002 35.7V32.8V30.9V16.9C64.002 16.4 63.602 16 63.102 16H46.902C46.402 16 46.002 16.4 46.002 16.9Z"
      fill="#E02D00"
    />
    <path
      d="M53.5 29C53.5 28.1716 54.1716 27.5 55 27.5C55.8284 27.5 56.5 28.1716 56.5 29C56.5 29.8284 55.8284 30.5 55 30.5C54.1716 30.5 53.5 29.8284 53.5 29Z"
      fill="white"
    />
    <path d="M56.002 19H54.002V26.5C55.1065 26.5 56.002 25.6046 56.002 24.5V19Z" fill="white" />
  </svg>
</template>
