<template>
  <svg width="24" height="24" view-box="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.58963 3.5862C5.7519 3.2292 6.10786 3 6.5 3H17.5C17.8921 3 18.2481 3.2292 18.4104 3.5862L20.5518 8.29742C20.8472 8.9472 21 9.65268 21 10.3664V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V10.3664C3 9.65268 3.15281 8.9472 3.44817 8.29742L5.58963 3.5862ZM7.14391 5L5.553 8.5H9.5C10.0523 8.5 10.5 8.94772 10.5 9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H5V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V10.3664C19 9.93818 18.9083 9.51489 18.7311 9.12503L16.8561 5H13V6.5C13 7.05228 12.5523 7.5 12 7.5C11.4477 7.5 11 7.05228 11 6.5V5H7.14391ZM16.7071 9.29289C17.0976 9.68342 17.0976 10.3166 16.7071 10.7071L10.7071 16.7071C10.3166 17.0976 9.68342 17.0976 9.29289 16.7071L6.79289 14.2071C6.40237 13.8166 6.40237 13.1834 6.79289 12.7929C7.18342 12.4024 7.81658 12.4024 8.20711 12.7929L10 14.5858L15.2929 9.29289C15.6834 8.90237 16.3166 8.90237 16.7071 9.29289Z"
      fill="#000"
    />
  </svg>
</template>
