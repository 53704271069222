"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validatePassword = void 0;
function validatePassword(password) {
    var passwordMinimum8Characters = password.length >= 8;
    var passwordContainsNumberOrSymbol = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password);
    var passwordContainsUppercaseLetter = /[A-Z]+/.test(password);
    return passwordMinimum8Characters && passwordContainsNumberOrSymbol && passwordContainsUppercaseLetter;
}
exports.validatePassword = validatePassword;
