"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCountryCode = void 0;
function getCountryCode(language) {
    var _a;
    if (!language)
        return;
    var countryCode = language.includes('-') ? (_a = language.split('-')) === null || _a === void 0 ? void 0 : _a.at(-1) : language;
    return countryCode === null || countryCode === void 0 ? void 0 : countryCode.toUpperCase();
}
exports.getCountryCode = getCountryCode;
