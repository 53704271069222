<template>
  <svg width="24" height="24" view-box="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1 8C1 5.23858 3.23858 3 6 3H13C14.6569 3 16 4.34315 16 6V7H18C18.9443 7 19.8334 7.44458 20.4 8.2L22.8 11.4C22.9298 11.5731 23 11.7836 23 12V16C23 17.3538 22.1033 18.4981 20.8715 18.8715C20.4981 20.1033 19.3538 21 18 21C16.6938 21 15.5825 20.1652 15.1707 19H9.82929C9.41746 20.1652 8.30622 21 7 21C5.69378 21 4.58254 20.1652 4.17071 19H4C2.34315 19 1 17.6569 1 16V8ZM4.17071 17C4.58254 15.8348 5.69378 15 7 15C8.30622 15 9.41746 15.8348 9.82929 17H15.1707C15.5825 15.8348 16.6938 15 18 15C19.1937 15 20.2246 15.6972 20.7076 16.7066C20.8883 16.5257 21 16.2759 21 16V12.3333L18.8 9.4C18.6111 9.14819 18.3148 9 18 9H16V12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12V6C14 5.44772 13.5523 5 13 5H6C4.34315 5 3 6.34315 3 8V16C3 16.5523 3.44772 17 4 17H4.17071ZM8 18C8 17.4477 7.55228 17 7 17C6.44772 17 6 17.4477 6 18C6 18.5523 6.44772 19 7 19C7.55228 19 8 18.5523 8 18ZM18 17C17.4477 17 17 17.4477 17 18C17 18.5523 17.4477 19 18 19C18.5523 19 19 18.5523 19 18C19 17.4477 18.5523 17 18 17Z"
      fill="#000"
    />
  </svg>
</template>
