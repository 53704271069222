<template>
  <svg width="24" height="24" view-box="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 2C6.94772 2 6.5 2.44772 6.5 3C6.5 3.8022 6.27325 4.2345 5.97422 4.49083C5.64569 4.77244 5.04705 5.00009 4 5.00009C3.73478 5.00009 3.48043 5.10545 3.29289 5.29299C3.10536 5.48053 3 5.73488 3 6.0001C3.00001 9.32491 3.42422 12.4098 4.72318 15.1197C6.03331 17.853 8.19639 20.1281 11.5343 21.8849C11.8367 22.0441 12.1994 22.0378 12.4961 21.8682C15.5491 20.1237 17.7013 17.9932 19.0759 15.3342C20.444 12.6877 21 9.59804 21 6.00009C21 5.44781 20.5523 5.00009 20 5.00009C18.9529 5.00009 18.3543 4.77244 18.0258 4.49083C17.7267 4.2345 17.5 3.8022 17.5 3C17.5 2.44772 17.0523 2 16.5 2H7.5ZM7.27584 6.00931C7.89304 5.48026 8.25161 4.78721 8.40718 4H15.5928C15.7484 4.78721 16.107 5.48026 16.7242 6.00931C17.3348 6.53276 18.1118 6.81941 18.9861 6.93645C18.8958 9.91054 18.3631 12.3577 17.2992 14.4158C16.2003 16.5417 14.4977 18.3234 11.981 19.8508C9.26985 18.3198 7.5722 16.4364 6.52669 14.2552C5.52042 12.1559 5.08857 9.71883 5.01249 6.93663C5.88737 6.81972 6.66484 6.53305 7.27584 6.00931ZM10.5005 8C10.5005 7.44772 10.9482 7 11.5005 7C12.0526 7 12.5005 7.4478 12.5005 8.00024C12.5005 8.55242 12.0528 9 11.501 9C10.9484 9 10.5005 8.55197 10.5005 8ZM11.5005 5C9.84363 5 8.50049 6.34315 8.50049 8C8.50049 9.30644 9.33576 10.4175 10.501 10.8293L10.5005 17.4997C10.5003 18.052 10.9479 18.4998 11.5002 18.5C12.0524 18.5002 12.5003 18.0526 12.5005 17.5003L12.5007 17H13.501C14.0533 17 14.501 16.5523 14.501 16C14.501 15.4477 14.0533 15 13.501 15H12.501V14H13C13.5523 14 14 13.5523 14 13C14 12.4477 13.5523 12 13 12H12.501V10.8292C13.666 10.4173 14.5005 9.30609 14.5005 8.00024C14.5005 6.34355 13.1575 5 11.5005 5Z"
      fill="#000"
    />
  </svg>
</template>
