"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-0deeb016"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "container" };
var _hoisted_2 = { class: "icon" };
var _hoisted_3 = { class: "center" };
var vue_3 = require("vue");
var icons_1 = require("pn-design-assets/pn-assets/icons");
var oidc_context_1 = require("@/stores/oidc-context");
exports.default = (0, vue_1.defineComponent)({
    __name: 'CommonError',
    props: {
        reason: {
            type: String,
            default: 'Something went wrong',
        },
        description: {
            type: String,
            default: 'Please try again later',
        },
        autoFocus: {
            type: Boolean,
            default: true,
        },
    },
    setup: function (__props) {
        var props = __props;
        var oidcContextStore = (0, oidc_context_1.useOidcContextStore)();
        (0, vue_3.onMounted)(function () {
            requestAnimationFrame(function () {
                if (props.autoFocus) {
                    var button = document.querySelector('.retry-button button');
                    button === null || button === void 0 ? void 0 : button.focus();
                }
            });
        });
        var isLoading = (0, vue_3.ref)(false);
        var tryAgain = function () {
            isLoading.value = true;
            if (oidcContextStore.authorization_url) {
                window.location.href = oidcContextStore.authorization_url;
                return;
            }
            window.location.href = '/me';
        };
        return function (_ctx, _cache) {
            var _component_pn_icon = (0, vue_2.resolveComponent)("pn-icon");
            var _component_pn_button = (0, vue_2.resolveComponent)("pn-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createVNode)(_component_pn_icon, {
                        icon: (0, vue_2.unref)(icons_1.close),
                        color: "blue700"
                    }, null, 8, ["icon"])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(props.reason), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(props.description), 1)
                ]),
                (0, vue_2.createVNode)(_component_pn_button, {
                    class: "retry-button",
                    icon: (0, vue_2.unref)(icons_1.rotate),
                    loading: isLoading.value,
                    onClick: tryAgain
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.try-again')), 1)
                    ]; }),
                    _: 1
                }, 8, ["icon", "loading"])
            ]));
        };
    }
});
