"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModalErrorStore = void 0;
var pinia_1 = require("pinia");
exports.useModalErrorStore = (0, pinia_1.defineStore)({
    id: 'modal',
    state: function () { return ({
        statusCode: '',
        open: false,
    }); },
    actions: {
        openModal: function (statusCode) {
            this.open = true;
            this.statusCode = statusCode;
        },
        closeModal: function () {
            this.open = false;
        },
    },
});
