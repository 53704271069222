<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 560" fill="none" class="scale left">
    <g clip-path="url(#clip-person-computer)">
      <path
        d="M863.199 467.1H526.899C526.199 467.1 525.699 466.5 525.699 465.9V95.2C525.699 94.5 526.299 94 526.899 94H532.099C532.799 94 533.299 94.6 533.299 95.2C533.299 95.8 532.699 96.4 532.099 96.4H528.099V464.5H861.899V96.5H542.799C542.099 96.5 541.599 95.9 541.599 95.3C541.599 94.7 542.199 94.1 542.799 94.1H863.199C863.899 94.1 864.399 94.7 864.399 95.3V465.9C864.399 466.5 863.899 467.1 863.199 467.1Z"
        fill="white"
      ></path>
      <path
        d="M688.899 464.4C688.199 464.4 687.699 463.8 687.699 463.2V95.2C687.699 94.5 688.299 94 688.899 94C689.499 94 690.099 94.6 690.099 95.2V463.1C690.099 463.8 689.599 464.4 688.899 464.4Z"
        fill="white"
      ></path>
      <path
        d="M863.2 285.3H526.8C526.1 285.3 525.6 284.7 525.6 284.1C525.6 283.5 526.2 282.9 526.8 282.9H863.2C863.9 282.9 864.4 283.5 864.4 284.1C864.4 284.7 863.9 285.3 863.2 285.3Z"
        fill="white"
      ></path>
      <path
        d="M537.7 96.4996C536.9 96.4996 536.4 95.8996 536.4 95.2996C536.4 94.6996 537 94.0996 537.6 94.0996C538.2 94.0996 538.9 94.6996 538.9 95.2996C538.9 95.8996 538.4 96.4996 537.7 96.4996Z"
        fill="white"
      ></path>
      <path
        opacity="0.15"
        d="M176.099 551.1C176.999 553.8 177.999 556.4 179.199 558.9H798.299C798.499 557.8 798.799 556.7 798.899 555.6C802.399 534.3 794.699 513.5 792.699 492.5C790.899 473.8 794.699 452.8 802.699 435.8C809.199 421.9 817.699 408.8 822.999 394.4C833.799 365.4 830.999 332.6 820.099 303.6C813.199 285.3 802.899 267.9 787.999 255.2C759.199 230.6 721.799 236 687.599 244.1C648.499 253.3 616.599 277.8 580.899 294.6C567.099 301.1 552.199 306.5 536.899 306.2C518.799 305.8 501.699 297.6 486.799 287.3C456.599 266.5 430.599 240.2 400.499 219.3C370.799 198.7 330.899 186 298.899 202.7C254.099 225.9 259.099 290.8 212.999 311.1C199.199 317.1 184.199 320.4 171.199 328C140.199 346.1 127.199 385.8 130.499 421.5C134.799 467.7 161.699 507.8 176.099 551.1Z"
        fill="#F89142"
      ></path>
      <path
        d="M356.8 179.5L511.7 143.8C551.4 134.7 589.3 164.8 589.3 205.6C589.3 241.6 559.3 270.4 523.3 268.9L509.8 268.3V349.1H376.1C328.6 349.1 290.1 310.6 290.1 263.1C290.2 223.2 317.8 188.5 356.8 179.5Z"
        fill="#F89142"
      ></path>
      <path d="M532.5 264.1H477.5V335.3H532.5V264.1Z" fill="#FFF1E3"></path>
      <path
        d="M524.099 309.6L514.999 309.3C486.099 308.4 463.399 284.1 464.299 255.2L465.499 218.9C466.399 190 490.699 167.3 519.599 168.2C548.499 169.1 571.199 193.4 570.299 222.3L568.799 267.8C567.899 291.7 547.899 310.4 524.099 309.6Z"
        fill="#FFF1E3"
      ></path>
      <path
        d="M570.5 197.8C561.4 183.7 542.4 167.9 525.6 164.9C519 163.7 512.2 164.4 505.6 165.6C497 167.1 488.5 169.4 481.2 174.1C460.9 187.2 462 218.9 463.9 240.3C521.7 242.8 549.2 198.7 549.2 198.7C549.2 198.7 562.8 220.7 581.8 221.5C581.8 221.3 580.8 213.7 570.5 197.8Z"
        fill="#F89142"
      ></path>
      <path
        d="M461.5 261.8C470.116 261.8 477.1 254.815 477.1 246.2C477.1 237.584 470.116 230.6 461.5 230.6C452.885 230.6 445.9 237.584 445.9 246.2C445.9 254.815 452.885 261.8 461.5 261.8Z"
        fill="#FFF1E3"
      ></path>
      <path
        d="M431.3 328.1H562.8C587.7 328.1 609.8 344.3 617.2 368.2L676.7 560.1H366.8C342.8 560.1 325.3 537.2 331.7 514L370.3 374.6C377.8 347.1 402.8 328.1 431.3 328.1Z"
        fill="#005D92"
      ></path>
      <path
        d="M505.2 357.6H504.8C489.7 357.6 477.5 345.4 477.5 330.3V316.4H532.5V330.3C532.5 345.3 520.2 357.6 505.2 357.6Z"
        fill="#FFF1E3"
      ></path>
      <path
        d="M507.2 518L525 520.5L566.7 508.7C571.6 507.3 577 508.2 581.1 511.1L620.7 538.8V546.4H613.3L613.2 550.4H606.2C603.3 550.4 600.8 551.2 598.4 549.6H597.1L597.2 550.4H585.9L581.4 549.8L580.8 550.4H566.6L550.9 548C550.9 548 547.7 557.1 528.1 560.1H501.9L507.2 518Z"
        fill="#FFF1E3"
      ></path>
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M582.699 549.9C582.499 549.9 582.299 549.8 582.099 549.7L558.499 535.6C557.899 535.2 557.699 534.5 558.099 533.9C558.499 533.3 559.199 533.1 559.799 533.5L583.399 547.6C583.999 548 584.199 548.7 583.799 549.3C583.499 549.7 583.099 549.9 582.699 549.9Z"
          fill="#99290A"
        ></path>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M559.399 548.8C559.199 548.8 558.899 548.7 558.699 548.6L549.399 542.7C548.799 542.3 548.599 541.6 548.999 541C549.399 540.4 550.099 540.2 550.699 540.6L559.999 546.5C560.599 546.9 560.799 547.6 560.399 548.2C560.199 548.6 559.799 548.8 559.399 548.8Z"
          fill="#99290A"
        ></path>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M600.099 550.5C599.899 550.5 599.599 550.4 599.399 550.3L570.999 531C570.399 530.6 570.299 529.8 570.699 529.3C571.099 528.7 571.899 528.6 572.399 529L600.799 548.3C601.399 548.7 601.499 549.5 601.099 550C600.899 550.3 600.499 550.5 600.099 550.5Z"
          fill="#99290A"
        ></path>
      </g>
      <g opacity="0.5">
        <path
          opacity="0.2"
          d="M613.4 547.6C613.2 547.6 612.9 547.5 612.7 547.4L583.8 526.9C583.2 526.5 583.1 525.7 583.5 525.2C583.9 524.6 584.7 524.5 585.2 524.9L614.1 545.4C614.7 545.8 614.8 546.6 614.4 547.1C614.1 547.4 613.8 547.6 613.4 547.6Z"
          fill="#99290A"
        ></path>
      </g>
      <path
        d="M595.299 560.1H527.199V558.8C527.199 551.4 533.199 545.3 540.699 545.3H595.399V560.1H595.299Z"
        fill="#0D234B"
      ></path>
      <path
        d="M532.5 309.2C530.6 309.5 518.6 311 505.4 307.9C504.7 307.7 504.1 308.2 503.9 308.8C503.7 309.5 504.2 310.1 504.8 310.3C511.5 311.9 517.8 312.3 522.7 312.3C527.5 312.3 531 311.9 532.4 311.7V309.2H532.5Z"
        fill="#500715"
      ></path>
      <path
        d="M793.2 560.1H590.6L631.6 446.3C634.5 438.3 642.1 432.9 650.7 432.9H821.7C830.1 432.9 836 441.3 833.2 449.2L793.2 560.1Z"
        fill="#ABE3BB"
      ></path>
      <path
        d="M717.4 511.5C725.297 511.5 731.7 505.098 731.7 497.2C731.7 489.303 725.297 482.9 717.4 482.9C709.502 482.9 703.1 489.303 703.1 497.2C703.1 505.098 709.502 511.5 717.4 511.5Z"
        fill="#E8F9EF"
      ></path>
      <path
        d="M573.499 495.1C573.399 495.1 573.299 495.1 573.199 495.1C572.499 495 572.099 494.3 572.199 493.6L584.399 438.5C584.599 437.8 585.199 437.4 585.899 437.6C586.599 437.7 586.999 438.4 586.899 439.1L574.699 494.2C574.599 494.7 574.099 495.1 573.499 495.1Z"
        fill="#0D234B"
      ></path>
      <path
        d="M464.799 251.5C464.299 251.5 463.799 251.2 463.599 250.7L460.199 241C459.999 240.3 460.299 239.6 460.999 239.4C461.699 239.2 462.399 239.5 462.599 240.2L465.999 249.9C466.199 250.6 465.899 251.3 465.199 251.5C464.999 251.4 464.899 251.5 464.799 251.5Z"
        fill="#500715"
      ></path>
      <path
        d="M463.6 248.8H456.8C456.1 248.8 455.6 248.2 455.6 247.6C455.6 247 456.2 246.4 456.8 246.4H463.6C464.3 246.4 464.8 247 464.8 247.6C464.8 248.2 464.3 248.8 463.6 248.8Z"
        fill="#500715"
      ></path>
      <path
        d="M587 434.7C586.8 434.7 586.7 434.7 586.5 434.6C585.9 434.3 585.6 433.6 585.8 433L585.9 432.9C586.2 432.3 586.9 432 587.5 432.2C588.1 432.5 588.4 433.2 588.2 433.8L588.1 433.9C588 434.4 587.5 434.7 587 434.7Z"
        fill="#0D234B"
      ></path>
      <path d="M419.5 444.5L409 491.3L446.9 498.4L419.5 444.5Z" fill="#0D234B"></path>
      <path
        d="M792.797 173.4C792.797 158.8 804.597 147.1 819.097 147.1C833.697 147.1 845.397 158.9 845.397 173.4H792.797Z"
        fill="white"
      ></path>
      <path
        d="M741.998 173.4C741.998 158.8 753.798 147.1 768.298 147.1C782.798 147.1 794.598 158.9 794.598 173.4H741.998Z"
        fill="white"
      ></path>
    </g>
    <defs>
      <clipPath id="clip-person-computer"><rect width="1000" height="560" fill="white"></rect></clipPath>
    </defs>
  </svg>
</template>
