"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = {
    xmlns: "http://www.w3.org/2000/svg",
    width: "18",
    height: "18",
    viewBox: "0 0 20 20",
    fill: ""
};
var _hoisted_2 = ["fill"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'GlobeIcon',
    props: {
        fill: {
            type: String,
            default: '#5e554a',
        },
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("svg", _hoisted_1, [
                (0, vue_2.createElementVNode)("path", {
                    "fill-rule": "evenodd",
                    "clip-rule": "evenodd",
                    d: "M2.25204 8C2.08751 8.63924 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25204 12H5.41769C5.22354 10.6743 5.22354 9.32568 5.41769 8H2.25204ZM3.07026 6H5.86493C6.25013 4.73113 6.82305 3.50704 7.58369 2.37138C5.66551 2.97839 4.06009 4.28887 3.07026 6ZM10 2.47928C9.10279 3.55047 8.4269 4.74419 7.97233 6L12.0277 6C11.5731 4.74419 10.8972 3.55047 10 2.47928ZM12.5571 8L7.44288 8C7.21514 9.32282 7.21514 10.6772 7.44288 12H12.5571C12.7849 10.6772 12.7849 9.32281 12.5571 8ZM14.5823 12C14.7765 10.6743 14.7765 9.32568 14.5823 8H17.748C17.9125 8.63924 18 9.3094 18 10C18 10.6906 17.9125 11.3608 17.748 12H14.5823ZM12.0277 14H7.97233C8.4269 15.2558 9.10279 16.4495 10 17.5207C10.8972 16.4495 11.5731 15.2558 12.0277 14ZM7.58369 17.6286C6.82305 16.493 6.25013 15.2689 5.86493 14H3.07026C4.06009 15.7111 5.66551 17.0216 7.58369 17.6286ZM12.4163 17.6286C13.1769 16.493 13.7499 15.2689 14.1351 14H16.9297C15.9399 15.7111 14.3345 17.0216 12.4163 17.6286ZM16.9297 6H14.1351C13.7499 4.73113 13.1769 3.50704 12.4163 2.37138C14.3345 2.97839 15.9399 4.28887 16.9297 6ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z",
                    fill: __props.fill
                }, null, 8, _hoisted_2)
            ]));
        };
    }
});
