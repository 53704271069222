"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var use_auth_flow_1 = require("@/composables/use-auth-flow");
var auth_flow_1 = require("@/stores/auth-flow");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ChangeEmailButton',
    setup: function (__props) {
        var changeEmail = (0, use_auth_flow_1.useAuthFlow)().changeEmail;
        var authFlowStore = (0, auth_flow_1.useAuthFlowStore)();
        return function (_ctx, _cache) {
            var _component_pn_button = (0, vue_2.resolveComponent)("pn-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_pn_button, {
                small: "",
                appearance: "light",
                class: "change-email-button",
                variant: "borderless",
                loading: (0, vue_2.unref)(authFlowStore).isChangingEmail,
                onClick: (0, vue_2.unref)(changeEmail)
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('login_page.change-email')), 1)
                ]; }),
                _: 1
            }, 8, ["loading", "onClick"]));
        };
    }
});
