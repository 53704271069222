<template>
  <svg aria-hidden="true" width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.8747 15C31.8747 13.2741 33.2738 11.875 34.9997 11.875H84.9997C86.7256 11.875 88.1247 13.2741 88.1247 15C88.1247 19.3817 89.3802 22.1388 91.4085 23.8773C93.5289 25.6949 97.0403 26.875 102.5 26.875C104.121 26.875 105.473 28.1144 105.613 29.7293C107.159 47.5139 104.528 62.202 98.2529 74.5893C91.9847 86.9638 82.2026 96.8034 69.8321 105.096L69.784 105.128L61.6427 110.158C60.1744 111.066 58.2488 110.611 57.3416 109.143C56.4344 107.674 56.8892 105.749 58.3574 104.842L66.3998 99.8722C78.1122 92.0126 87.0231 82.9277 92.6774 71.7651C98.0216 61.2146 100.565 48.5946 99.5922 33.0211C94.6525 32.6541 90.4588 31.2951 87.341 28.6227C84.2641 25.9853 82.589 22.3793 82.0606 18.125H37.9388C37.4104 22.3793 35.7354 25.9853 32.6585 28.6227C29.5397 31.2959 25.3443 32.6549 20.4026 33.0214C18.6698 59.3725 25.7799 77.3588 41.3994 91.2305C42.6898 92.3765 42.8069 94.3517 41.6608 95.6422C40.5148 96.9326 38.5396 97.0497 37.2491 95.9036C19.327 79.9869 11.8291 59.1394 14.3865 29.7293C14.5269 28.1144 15.8788 26.875 17.4997 26.875C22.9591 26.875 26.4706 25.6949 28.591 23.8773C30.6193 22.1388 31.8747 19.3817 31.8747 15ZM80.9598 45.2903C82.1802 46.5107 82.1801 48.4893 80.9598 49.7097L58.4598 72.2097C57.2394 73.4301 55.2607 73.4301 54.0403 72.2097L41.5403 59.7097C40.32 58.4893 40.32 56.5107 41.5403 55.2903C42.7607 54.0699 44.7394 54.0699 45.9598 55.2903L56.25 65.5806L76.5403 45.2903C77.7607 44.0699 79.7394 44.0699 80.9598 45.2903ZM48.75 97.643C50.4759 97.643 51.875 99.0421 51.875 100.768V100.793C51.875 102.519 50.4759 103.918 48.75 103.918C47.0241 103.918 45.625 102.519 45.625 100.793V100.768C45.625 99.0421 47.0241 97.643 48.75 97.643Z"
      fill="white"
    ></path>
  </svg>
</template>
