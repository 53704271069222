<template>
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20.3203" r="20" fill="#E0F8FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.7497 20.3205C28.7497 15.488 24.8322 11.5705 19.9997 11.5705C17.7112 11.5705 15.6292 12.4482 14.0695 13.8866C13.7875 14.1466 13.3482 14.1288 13.0882 13.8469C12.8282 13.5649 12.846 13.1256 13.1279 12.8656C14.934 11.2 17.3487 10.1816 19.9997 10.1816C25.5993 10.1816 30.1386 14.721 30.1386 20.3205C30.1386 25.9201 25.5993 30.4594 19.9997 30.4594C14.4002 30.4594 9.86084 25.9201 9.86084 20.3205C9.86084 19.937 10.1718 19.6261 10.5553 19.6261C10.9388 19.6261 11.2497 19.937 11.2497 20.3205C11.2497 22.8516 12.3244 25.1316 14.0423 26.7293C15.1291 24.4339 17.4454 22.9594 19.9997 22.9594C22.554 22.9594 24.8703 24.4339 25.9571 26.7293C27.6751 25.1316 28.7497 22.8516 28.7497 20.3205ZM24.8314 27.6167L24.7822 27.5019C23.9623 25.5887 22.0811 24.3483 19.9997 24.3483C17.9183 24.3483 16.0371 25.5887 15.2172 27.5019L15.168 27.6167C16.5525 28.5354 18.2136 29.0705 19.9997 29.0705C21.7858 29.0705 23.4469 28.5354 24.8314 27.6167ZM13.0553 14.9039C13.0553 15.2874 12.7444 15.5983 12.3608 15.5983C11.9773 15.5983 11.6664 15.2874 11.6664 14.9039C11.6664 14.5203 11.9773 14.2094 12.3608 14.2094C12.7444 14.2094 13.0553 14.5203 13.0553 14.9039ZM17.9164 18.0983C17.9164 16.9477 18.8491 16.015 19.9997 16.015C21.1503 16.015 22.083 16.9477 22.083 18.0983C22.083 19.2489 21.1503 20.1816 19.9997 20.1816C18.8491 20.1816 17.9164 19.2489 17.9164 18.0983ZM19.9997 14.6261C18.082 14.6261 16.5275 16.1807 16.5275 18.0983C16.5275 20.016 18.082 21.5705 19.9997 21.5705C21.9174 21.5705 23.4719 20.016 23.4719 18.0983C23.4719 16.1807 21.9174 14.6261 19.9997 14.6261Z"
      fill="#005D92"
    />
  </svg>
</template>
