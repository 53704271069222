"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAuthFlowStore = void 0;
var pinia_1 = require("pinia");
exports.useAuthFlowStore = (0, pinia_1.defineStore)({
    id: 'auth-flow',
    state: function () {
        return {
            isSelectAccount: false,
            continued: false,
            email: '',
            isLoginSbid: false,
            loginMethod: 'otp',
            loginMethods: new Set([]),
            signupMethod: 'pwd',
            signupMethods: new Set([]),
            isChangingEmail: false,
            isChangingRoute: true,
        };
    },
    getters: {
        hasEmailInput: function (state) {
            return state.email && state.continued;
        },
        isLoginPwd: function (state) {
            return state.loginMethod === 'pwd';
        },
        isLoginOtp: function (state) {
            return state.loginMethod === 'otp';
        },
        isSignupPwd: function (state) {
            return state.signupMethod === 'pwd';
        },
        isSignupOtp: function (state) {
            return state.signupMethod === 'otp';
        },
    },
});
