"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Languages = exports.LanguageCode = void 0;
var LanguageCode;
(function (LanguageCode) {
    LanguageCode["Svenska"] = "sv";
    LanguageCode["Dansk"] = "da";
    LanguageCode["Suomi"] = "fi";
    LanguageCode["Norsk"] = "no";
    LanguageCode["English"] = "en";
})(LanguageCode = exports.LanguageCode || (exports.LanguageCode = {}));
var Languages;
(function (Languages) {
    Languages["Svenska"] = "Svenska";
    Languages["Dansk"] = "Dansk";
    Languages["Suomi"] = "Suomi";
    Languages["Norsk"] = "Norsk";
    Languages["English"] = "English";
})(Languages = exports.Languages || (exports.Languages = {}));
