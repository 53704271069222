<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" fill="#FDEFEE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4882 18.4882C19.139 17.8373 20.1943 17.8373 20.8452 18.4882L28 25.643L35.1548 18.4882C35.8057 17.8373 36.861 17.8373 37.5118 18.4882C38.1627 19.139 38.1627 20.1943 37.5118 20.8452L30.357 28L37.5118 35.1548C38.1627 35.8057 38.1627 36.861 37.5118 37.5118C36.861 38.1627 35.8057 38.1627 35.1548 37.5118L28 30.357L20.8452 37.5118C20.1943 38.1627 19.139 38.1627 18.4882 37.5118C17.8373 36.861 17.8373 35.8057 18.4882 35.1548L25.643 28L18.4882 20.8452C17.8373 20.1943 17.8373 19.139 18.4882 18.4882Z"
      fill="#F06365"
    />
  </svg>
</template>
