<template>
  <svg width="166" height="184" viewBox="0 0 166 184" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.9409 148.477H53.9794C54.2489 148.477 54.4414 148.245 54.4414 148.014C54.4414 147.782 54.2104 147.551 53.9794 147.551H53.9409C53.6714 147.551 53.4789 147.782 53.4789 148.014C53.4789 148.245 53.6714 148.477 53.9409 148.477Z"
      fill="#5EC584"
    ></path>
    <path
      d="M126.244 110.098H126.282C126.552 110.098 126.744 109.866 126.744 109.635C126.744 109.403 126.513 109.172 126.282 109.172H126.244C125.974 109.172 125.782 109.403 125.782 109.635C125.782 109.866 125.974 110.098 126.244 110.098Z"
      fill="#5EC584"
    ></path>
    <path
      d="M105.531 120.167C105.801 120.167 105.993 119.936 105.993 119.705V119.666C105.993 119.396 105.762 119.203 105.531 119.203C105.3 119.203 105.069 119.435 105.069 119.666V119.705C105.069 119.936 105.262 120.167 105.531 120.167Z"
      fill="#5EC584"
    ></path>
    <path
      d="M116.503 93.9357H149.651V50.2343C149.651 38.3929 140.065 28.75 128.207 28.75H116.464V93.9357H116.503Z"
      fill="black"
    ></path>
    <path
      d="M77.5793 93.937H139.757V31.1428C139.757 13.9399 125.82 0.015625 108.687 0.015625C91.5164 0.015625 77.6179 13.9785 77.6179 31.1428V93.937H77.5793Z"
      fill="black"
    ></path>
    <path
      d="M102.104 67.7869L105.8 67.6712C117.504 67.2854 126.667 57.4883 126.282 45.7626L125.782 31.0669C125.397 19.3412 115.618 10.1612 103.914 10.5469C92.2097 10.9326 83.0467 20.7297 83.4317 32.4555L84.0477 50.854C84.3557 60.5355 92.4407 68.0955 102.104 67.7869Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M123.549 39.6275H119.121C115.695 39.6275 112.961 36.8504 112.961 33.4561V28.7504C100.179 35.7704 79.4663 31.9133 79.4663 31.9133L84.2788 14.0547L115.271 18.8761L123.549 39.6275Z"
      fill="black"
    ></path>
    <path
      d="M123.548 39.6289H135.599L123.548 10.1989L107.648 5.87891L90.3611 8.88748L85.1636 21.1918L116.811 22.7732L123.548 39.6289Z"
      fill="black"
    ></path>
    <path
      d="M127.398 48.421C123.911 48.421 121.084 45.5888 121.084 42.0952C121.084 38.6016 123.911 35.7695 127.398 35.7695C130.885 35.7695 133.712 38.6016 133.712 42.0952C133.712 45.5888 130.885 48.421 127.398 48.421Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M125.589 44.7188C125.781 44.7188 125.974 44.6031 126.051 44.4102L127.86 39.2031C127.937 38.9331 127.822 38.6631 127.552 38.586C127.283 38.5088 127.013 38.6245 126.936 38.8945L125.127 44.1017C125.05 44.3717 125.165 44.6417 125.435 44.7188C125.473 44.7188 125.512 44.7188 125.589 44.7188Z"
      fill="#500715"
    ></path>
    <path
      d="M126.166 43.2929H129.785C130.055 43.2929 130.247 43.0615 130.247 42.83C130.247 42.5986 130.016 42.3672 129.785 42.3672H126.166C125.897 42.3672 125.704 42.5986 125.704 42.83C125.704 43.0615 125.897 43.2929 126.166 43.2929Z"
      fill="#500715"
    ></path>
    <path
      d="M110.496 85.2198C117.118 85.2198 122.316 79.5498 121.777 72.9155L120.699 58.9141L99.1775 62.6169V73.8798C99.1775 80.1283 104.221 85.2198 110.496 85.2198Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M102.913 68.4799C105.762 68.4799 109.997 68.0556 113.693 65.8956C113.885 65.7799 113.924 65.5485 113.847 65.3556C113.731 65.1627 113.5 65.1242 113.308 65.2013C107.379 68.5956 99.7556 67.477 99.6786 67.477C99.4861 67.4385 99.2551 67.5927 99.2551 67.7856C99.2166 67.9785 99.3706 68.2099 99.5631 68.2099C99.6786 68.287 100.988 68.4799 102.913 68.4799Z"
      fill="#4F1600"
    ></path>
    <path d="M60.7941 143.5H147.496V183.884H60.7941V143.5Z" fill="#F89142"></path>
    <path
      d="M19.56 109.675L16.711 102.655C16.711 102.655 11.552 98.9904 9.20351 94.2461L24.3725 94.2847L30.6865 109.636H19.56V109.675Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M10.0127 67.8252L3.66019 62.7338C2.69768 61.9624 1.31168 62.1552 0.58018 63.1195C-0.0743208 63.9681 0.00266552 65.2024 0.734166 65.9738L9.97418 75.4624V67.8252H10.0127Z"
      fill="#FFF1E3"
    ></path>
    <path d="M5.89258 64.5463L4.69907 63.582L5.35357 70.6792L5.89258 71.2192V64.5463Z" fill="#4F1600"></path>
    <path d="M3.15945 74.6923L6.85547 74.1523V76.2352L3.15945 74.6923Z" fill="#4F1600"></path>
    <path d="M2.35097 80.1313L6.20096 79.0898L6.85547 82.0984L2.35097 80.1313Z" fill="#4F1600"></path>
    <path d="M2.65866 85.8393L5.93115 84.875L6.43164 87.6907L2.65866 85.8393Z" fill="#4F1600"></path>
    <path
      d="M5.66168 60.7282V58.7611C5.66168 57.5654 6.62418 56.5625 7.77918 56.5625H24.1032C25.2582 56.5625 26.2207 57.5268 26.2207 58.7611V60.7282H5.66168Z"
      fill="black"
    ></path>
    <path
      d="M26.2207 90.8906V93.2049C26.2207 94.4006 25.2582 95.4035 24.1032 95.4035H7.77918C6.62418 95.4035 5.66168 94.4392 5.66168 93.2049V90.8906H26.2207Z"
      fill="black"
    ></path>
    <path d="M26.2528 90.8672L5.65527 90.8672L5.65527 60.7043L26.2528 60.7043L26.2528 90.8672Z" fill="#005D92"></path>
    <path
      d="M16.0184 81.5166C12.1485 81.5166 9.01135 78.3736 9.01135 74.4966C9.01135 70.6195 12.1485 67.4766 16.0184 67.4766C19.8882 67.4766 23.0254 70.6195 23.0254 74.4966C23.0254 78.3736 19.8882 81.5166 16.0184 81.5166Z"
      fill="white"
    ></path>
    <path
      d="M17.5586 74.6867L17.1736 72.2953C17.3276 72.2953 17.5971 72.2953 17.5971 72.2953C17.7896 72.2953 18.0206 72.1796 18.0976 71.9867C18.1361 71.9096 18.1746 71.6782 17.8666 71.4853C17.7511 71.4082 17.6741 71.331 17.6741 71.2539C17.6741 71.1767 17.6741 71.1382 17.7126 71.061C17.7896 70.9839 17.9051 70.9453 18.0976 70.9453C18.4056 70.9453 18.5981 71.0996 18.5981 71.2539C18.5981 71.331 18.5596 71.4082 18.4826 71.4467C18.2901 71.5625 18.2516 71.7939 18.3671 71.9482C18.4826 72.141 18.7136 72.2567 19.0216 72.2567C19.0216 72.2567 19.2911 72.2567 19.4451 72.2567C19.4836 72.4882 20.0611 76.0753 20.0996 76.3839H17.8281C17.7896 76.2682 17.7126 75.6125 17.5586 74.6867Z"
      fill="#469BBE"
    ></path>
    <path
      d="M14.5558 70.1758H16.8658L17.1738 72.1429H16.7888C16.5578 72.1429 16.3653 72.0272 16.2883 71.9115C16.2498 71.8729 16.2498 71.8344 16.2498 71.7958C16.2498 71.7186 16.2883 71.6415 16.3653 71.6029C16.5193 71.4872 16.5578 71.3715 16.5578 71.2944V71.2558C16.5193 71.0629 16.2498 70.8315 15.8648 70.8315C15.6338 70.8315 15.4798 70.8701 15.3643 70.9858C15.2873 71.0629 15.2488 71.1786 15.2488 71.2944C15.2873 71.4486 15.4413 71.5644 15.5183 71.6415C15.7493 71.7958 15.7108 71.9501 15.7108 71.9886C15.6723 72.1044 15.4798 72.1815 15.3643 72.1815H14.7868C13.9783 72.1815 13.5549 72.5672 13.6704 73.4158C13.7859 74.2258 14.4018 74.5729 15.1333 74.5729L15.4413 76.4244H15.0178C13.2084 76.4244 11.7068 75.2672 11.4373 73.4158C11.0138 71.1015 12.4769 70.1758 14.5558 70.1758Z"
      fill="#225870"
    ></path>
    <path
      d="M20.2153 77.2344H19.2913C18.9063 77.2344 18.7908 77.4272 18.8293 77.6201C18.8678 77.7744 18.9448 77.8901 19.1373 77.9672C18.9063 78.0444 18.8293 78.1987 18.8678 78.3915C18.9063 78.6615 19.1373 78.8544 19.4068 78.8544H20.4463L20.2153 77.2344ZM19.5993 77.8901C19.4068 77.8901 19.3298 77.8129 19.3298 77.6972C19.3298 77.5815 19.3683 77.5044 19.5608 77.5044H19.6763L19.7533 77.9287H19.5993V77.8901ZM19.6763 78.5844C19.4838 78.5844 19.3683 78.5072 19.3683 78.3529C19.3298 78.2372 19.4068 78.1215 19.5993 78.1215H19.7918L19.8688 78.5458H19.6763V78.5844Z"
      fill="#225870"
    ></path>
    <path
      d="M17.5591 78.8163C17.7901 78.8163 17.9055 78.8163 17.9825 78.7006C18.098 78.7777 18.2521 78.8163 18.4061 78.8163C18.6756 78.8163 18.791 78.662 18.7525 78.5077C18.7525 78.4306 18.7141 78.3535 18.6371 78.3149C18.4831 78.1992 18.1365 78.1606 17.9825 78.0835C17.9825 77.9677 18.0211 77.9292 18.1366 77.9292C18.2906 77.9292 18.4446 77.9677 18.6371 78.122L18.5986 77.7749C18.4061 77.6206 18.2135 77.582 17.9825 77.582C17.713 77.582 17.4435 77.6977 17.482 78.0063L17.5206 78.3535C17.5206 78.4692 17.5205 78.5077 17.405 78.5077L17.5591 78.8163ZM17.9825 78.2763C18.098 78.3535 18.329 78.3535 18.3675 78.5077C18.3675 78.5849 18.3291 78.662 18.2521 78.662C18.1751 78.662 18.0981 78.6235 18.0211 78.5849C18.0211 78.5463 18.0211 78.5077 18.0211 78.4692L17.9825 78.2763Z"
      fill="#225870"
    ></path>
    <path
      d="M17.0966 77.5815H16.6345L16.673 77.7358C16.519 77.6201 16.4035 77.543 16.2495 77.543C15.98 77.543 15.8645 77.6973 15.903 77.9673L16.0185 78.7773H16.4805L16.365 78.1215C16.365 78.0058 16.365 77.9287 16.4805 77.9287C16.5575 77.9287 16.6345 77.9673 16.7115 78.0444L16.827 78.7773H17.2891L17.0966 77.5815Z"
      fill="#225870"
    ></path>
    <path
      d="M15.518 77.2344H15.056L15.1715 78.0058L14.7095 77.5815H14.132L14.7095 78.0829L14.2475 78.7772H14.8635L15.21 78.1987L15.287 78.7772H15.749L15.518 77.2344Z"
      fill="#225870"
    ></path>
    <path d="M13.8246 77.2344H13.2857L13.5167 78.8158H14.0557L13.8246 77.2344Z" fill="#469BBE"></path>
    <path
      d="M12.978 77.2344H12.1695C11.5535 77.2344 11.3995 77.6972 11.438 78.0444C11.4765 78.3915 11.7845 78.8158 12.3235 78.8158H13.209L12.978 77.2344ZM12.4775 78.4301C12.208 78.4301 12.054 78.3144 12.0155 78.0058C11.977 77.8129 12.054 77.5815 12.362 77.5815H12.516L12.6315 78.3915H12.4775V78.4301Z"
      fill="#469BBE"
    ></path>
    <path
      d="M6.93229 81.4398L3.54427 80.2055C2.23527 79.7426 0.810791 80.5912 0.579791 81.9412L0.541276 82.1341C0.348777 83.2527 1.04179 84.2941 2.11979 84.6027L5.54627 85.6055C6.85527 85.9912 8.16428 85.1427 8.39528 83.8312C8.54928 82.8284 7.97179 81.7869 6.93229 81.4398Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M7.5478 76.0414L4.15982 74.8071C2.85082 74.3442 1.42632 75.1928 1.19532 76.5428L1.15683 76.7356C0.964332 77.8542 1.65732 78.8956 2.73532 79.2042L6.16182 80.2071C7.47081 80.5928 8.77979 79.7442 9.01079 78.4328C9.12629 77.4299 8.5488 76.3885 7.5478 76.0414Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M6.73985 86.9167L3.85235 85.8753C2.73585 85.451 1.50384 86.1838 1.31134 87.3795L1.27285 87.5338C1.11885 88.4981 1.69635 89.3853 2.62035 89.6553L5.54633 90.5424C6.66283 90.8895 7.81783 90.1567 7.97183 88.9995C8.12583 88.1124 7.58684 87.2253 6.73985 86.9167Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M38.3867 109.677L34.1517 99.378C33.7667 98.4523 33.5742 97.4109 33.5742 96.408L33.7282 82.908C33.7282 82.3294 33.6512 81.7123 33.4202 81.1723L28.9157 69.0609C28.5692 68.1352 27.3757 67.8266 26.6442 68.4823C26.3362 68.7523 26.1437 69.138 26.1437 69.5623V81.5966C26.1437 81.5966 18.1742 89.0023 23.7952 98.568L27.7222 109.715H38.3867V109.677Z"
      fill="#FFF1E3"
    ></path>
    <path
      d="M23.6027 98.1805C23.6797 98.1805 23.7952 98.1419 23.8722 98.1033C24.1032 97.949 24.1417 97.6405 23.9877 97.4476C23.0637 96.1362 22.8712 95.4805 22.8712 95.4805C22.7942 95.2105 22.5247 95.0948 22.2937 95.1719C22.0242 95.249 21.9087 95.519 21.9472 95.7505C21.9857 95.8276 22.1782 96.5219 23.1792 97.9876C23.2947 98.1033 23.4487 98.1805 23.6027 98.1805Z"
      fill="#4F1600"
    ></path>
    <path d="M23.0254 96.6786L15.7104 95.3672H22.4864L23.0254 96.6786Z" fill="#4F1600"></path>
    <path
      d="M75.963 180.608C72.267 180.608 69.2255 177.638 69.187 173.897L68.802 153.84L63.412 163.097C60.0625 168.844 53.556 172.046 46.8185 171.043C40.6585 170.117 35.5765 165.604 33.6515 159.664L23.141 127.573L22.7945 126.416L22.217 124.603L17.443 109.637H40.2735L48.0505 127.573L48.2045 127.881L62.18 95.6742C66.184 86.4556 75.27 80.5156 85.28 80.5156H88.745H102.451H105.646"
      fill="#F89142"
    ></path>
    <path
      d="M94.4042 80.5156H97.5997H129.824H140.258C154.195 80.5156 165.514 91.8556 165.514 105.818V161.94C165.514 172.238 157.159 180.608 146.88 180.608H67.8392C64.1432 180.608 61.1017 177.638 61.0632 173.897L60.6782 153.84"
      fill="#F89142"
    ></path>
    <path
      d="M60.7936 166.298C61.0246 166.298 61.1786 166.144 61.1786 165.912V135.788C61.1786 132.934 61.9101 130.118 63.3346 127.65L70.7651 114.497C70.8806 114.304 70.8036 114.072 70.6111 113.957C70.4186 113.841 70.1876 113.918 70.0721 114.111L62.6416 127.264C61.1786 129.848 60.4086 132.78 60.4086 135.788V165.951C60.4086 166.144 60.5626 166.298 60.7936 166.298Z"
      fill="#002F24"
    ></path>
    <path
      d="M53.248 141.224C53.2865 141.224 53.3635 141.224 53.402 141.185C53.5945 141.108 53.6715 140.877 53.5945 140.684L47.396 126.605C47.319 126.412 47.088 126.335 46.8955 126.412C46.703 126.49 46.626 126.721 46.703 126.914L52.9015 140.992C52.9785 141.147 53.1325 141.224 53.248 141.224Z"
      fill="#002F24"
    ></path>
    <path
      d="M133.135 183.924H63.7583V110.484H139.988V177.059C139.988 180.839 136.908 183.924 133.135 183.924Z"
      fill="#005E41"
    ></path>
    <path
      d="M51.3997 183.925H65.7217V110.523H51.3997C47.6267 110.523 44.5467 113.609 44.5467 117.389V177.059C44.5467 180.839 47.5882 183.925 51.3997 183.925Z"
      fill="#002F24"
    ></path>
    <path
      d="M141.644 110.406L130.979 97.6391C130.633 97.2148 129.978 97.1762 129.555 97.5234C127.745 99.0662 127.437 101.766 128.823 103.695L134.868 111.988L141.644 110.406Z"
      fill="#4F1600"
    ></path>
    <path
      d="M157.391 143.347V130.966C157.391 129.732 156.967 128.536 156.159 127.61L139.45 107.823C139.065 107.399 138.526 107.129 137.987 107.129H116.042C114.54 107.129 113.308 108.363 113.308 109.867C113.308 111.372 114.502 112.606 116.003 112.606L128.67 112.76L113.886 112.567C112.461 112.529 111.306 113.686 111.306 115.113C111.306 116.502 112.423 117.659 113.809 117.659L129.786 117.813L116.504 117.697C115.041 117.697 113.847 118.893 113.886 120.397C113.924 121.786 115.079 122.866 116.465 122.866L132.828 122.943L122.587 122.905C121.162 122.905 120.046 124.062 120.084 125.45C120.123 126.8 121.201 127.88 122.548 127.919L133.944 128.073L140.066 130.156V143.309H157.391V143.347Z"
      fill="#FDEFEE"
    ></path>
    <path
      d="M116.003 112.875H128.631C128.9 112.875 129.093 112.643 129.093 112.412C129.093 112.181 128.862 111.949 128.631 111.949H115.964C115.695 111.949 115.502 112.181 115.502 112.412C115.502 112.643 115.733 112.875 116.003 112.875Z"
      fill="#4F1600"
    ></path>
    <path
      d="M116.465 117.969H130.556C130.825 117.969 131.018 117.737 131.018 117.506C131.018 117.274 130.787 117.043 130.556 117.043H116.465C116.195 117.043 116.003 117.274 116.003 117.506C116.003 117.737 116.195 117.969 116.465 117.969Z"
      fill="#4F1600"
    ></path>
    <path
      d="M122.548 123.176H132.789C133.058 123.176 133.251 122.944 133.251 122.713C133.251 122.481 133.02 122.25 132.789 122.25H122.548C122.278 122.25 122.086 122.481 122.086 122.713C122.086 122.944 122.278 123.176 122.548 123.176Z"
      fill="#4F1600"
    ></path>
    <path
      d="M98.0235 73.0703H102.875C104.376 73.0703 105.839 73.7646 106.802 74.9217L110.151 79.0103L113.501 74.9217C114.463 73.726 115.888 73.0703 117.428 73.0703H123.511C125.282 73.0703 126.706 74.4975 126.706 76.2717V86.3389H94.8665V76.2717C94.828 74.4975 96.2525 73.0703 98.0235 73.0703Z"
      fill="#F89142"
    ></path>
    <path
      d="M157.391 156.691C157.66 156.691 157.853 156.46 157.853 156.229V143.346C157.853 143.076 157.622 142.883 157.391 142.883C157.16 142.883 156.929 143.114 156.929 143.346V156.229C156.89 156.499 157.121 156.691 157.391 156.691Z"
      fill="#4F1600"
    ></path>
    <path
      d="M83.3549 174.32H92.0559C96.4449 174.32 100.025 170.733 100.025 166.336C100.025 161.939 96.4449 158.352 92.0559 158.352H83.3549C78.9659 158.352 75.3854 161.939 75.3854 166.336C75.3854 170.733 78.9274 174.32 83.3549 174.32Z"
      fill="#5EC584"
    ></path>
  </svg>
</template>
