"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOidcContext = void 0;
var oidc_context_1 = require("@/stores/oidc-context");
var SUPPORTED_LOGIN_METHODS = ['pwd', 'otp', 'pnfed', 'sbid', 'opnfed'];
var SUPPORTED_SIGNUP_METHODS = ['pwd', 'otp'];
var useOidcContext = function () {
    var oidcContextStore = (0, oidc_context_1.useOidcContextStore)();
    var saveOidcContext = function (context) {
        var _a, _b;
        oidcContextStore.authorization_params = context.authorization_params;
        oidcContextStore.authorization_url = context.authorization_url;
        oidcContextStore.client = context.client;
        oidcContextStore.email = context.email;
        oidcContextStore.flow = context.flow;
        oidcContextStore.has_name_claims = context.has_name_claims;
        oidcContextStore.is_mobile = context.is_mobile;
        oidcContextStore.language = context.language;
        oidcContextStore.uid = context.uid;
        oidcContextStore.sessions = context.sessions;
        var clientLoginMethods = new Set((_b = (_a = oidcContextStore.client) === null || _a === void 0 ? void 0 : _a.metadata) === null || _b === void 0 ? void 0 : _b.login_mech);
        oidcContextStore.loginMethods = new Set(SUPPORTED_LOGIN_METHODS.filter(function (method) { return clientLoginMethods.has(method); }));
        oidcContextStore.signupMethods = new Set(SUPPORTED_SIGNUP_METHODS.filter(function (method) { return clientLoginMethods.has(method); }));
    };
    return { saveOidcContext: saveOidcContext };
};
exports.useOidcContext = useOidcContext;
