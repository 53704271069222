"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-80972766"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "content" };
var _hoisted_2 = { class: "section" };
var _hoisted_3 = { class: "section" };
var _hoisted_4 = { class: "section" };
var _hoisted_5 = { class: "section" };
var _hoisted_6 = ["href", "title"];
var _hoisted_7 = { class: "section" };
var _hoisted_8 = ["title"];
var oidc_context_1 = require("@/stores/oidc-context");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Account',
    setup: function (__props) {
        var oidcContextStore = (0, oidc_context_1.useOidcContextStore)();
        var links = {
            da: 'https://www.postnord.dk/personlige-oplysninger?showOnetrust=false',
            fi: 'https://www.postnord.fi/yksityisyydensuoja?showOnetrust=false',
            no: 'https://www.postnord.no/personvernerklaering?showOnetrust=false',
            sv: 'https://www.postnord.se/integritetspolicy?showOnetrust=false',
            en: 'https://www.postnord.com/privacy-policy?showOnetrust=false',
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.header-what-is-postnord-account')), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info1-what-is-postnord-account')), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info2-what-is-postnord-account')), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_3, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.header-what-do-i-get-with-postnord-account')), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info-what-do-i-get-with-postnord-account')), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_4, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.header-how-do-i-manage-postnord-account')), 1),
                    (0, vue_2.createElementVNode)("p", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info-how-do-i-manage-postnord-account')), 1)
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_5, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.header-postnord-account-and-privacy')), 1),
                    (0, vue_2.createElementVNode)("p", null, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info-postnord-account-and-privacy')) + " ", 1),
                        (0, vue_2.createElementVNode)("a", {
                            class: "link",
                            href: links[(0, vue_2.unref)(oidcContextStore).language],
                            target: "_blank",
                            title: _ctx.$t('signup_page.policy-name')
                        }, (0, vue_2.toDisplayString)(_ctx.$t('signup_page.policy-name')) + " ➚", 9, _hoisted_6)
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_7, [
                    (0, vue_2.createElementVNode)("h2", null, (0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.header-how-can-i-ensure-it-is-postnord')), 1),
                    (0, vue_2.createElementVNode)("p", null, [
                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('about_postnord_account_page.info-how-can-i-ensure-it-is-postnord')) + " ", 1),
                        (0, vue_2.createElementVNode)("a", {
                            class: "link",
                            href: "/public/about/protect-your-account",
                            target: "_blank",
                            title: _ctx.$t('protect_postnord_account_page.tab-protect-postnord-accout')
                        }, (0, vue_2.toDisplayString)(_ctx.$t('protect_postnord_account_page.tab-protect-postnord-accout')) + " ➚", 9, _hoisted_8)
                    ])
                ])
            ]));
        };
    }
});
