<template>
  <svg width="24" height="24" view-box="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.50002 7.5C7.50002 5.01472 9.51474 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51474 12 7.50002 9.98528 7.50002 7.5ZM12 5C10.6193 5 9.50002 6.11929 9.50002 7.5C9.50002 8.88071 10.6193 10 12 10C13.3807 10 14.5 8.88071 14.5 7.5C14.5 6.11929 13.3807 5 12 5ZM4.92814 20.3723C4.72272 20.8845 4.14112 21.1335 3.62863 20.9285C3.11585 20.7234 2.86643 20.1414 3.07155 19.6286L3.95513 19.982C3.07155 19.6286 3.07174 19.6281 3.07194 19.6276L3.07239 19.6265L3.07343 19.6239L3.07609 19.6174L3.08375 19.5989C3.08991 19.5842 3.09818 19.5649 3.10863 19.5411C3.12954 19.4936 3.15921 19.4285 3.19817 19.3483C3.27603 19.188 3.39145 18.9665 3.54878 18.7043C3.86261 18.1812 4.34837 17.4874 5.04292 16.7929C6.44442 15.3914 8.68786 14 12 14C15.3122 14 17.5556 15.3914 18.9571 16.7929C19.6517 17.4874 20.1374 18.1812 20.4513 18.7043C20.6086 18.9665 20.724 19.188 20.8019 19.3483C20.8408 19.4285 20.8705 19.4936 20.8914 19.5411C20.9019 19.5649 20.9101 19.5842 20.9163 19.5989L20.924 19.6174L20.9266 19.6239L20.9281 19.6276C20.9283 19.6281 20.9285 19.6286 20.0449 19.982L20.9285 19.6286C21.1336 20.1414 20.8842 20.7234 20.3714 20.9285C19.8593 21.1333 19.2781 20.8848 19.0723 20.3733L19.0727 20.3743L19.0731 20.3754C19.0732 20.3755 19.0732 20.3754 19.0723 20.3733C19.0705 20.369 19.0664 20.3594 19.0608 20.3466C19.0495 20.3209 19.0303 20.2786 19.0029 20.2221C18.9479 20.1089 18.8602 19.9398 18.7363 19.7332C18.4876 19.3188 18.0984 18.7626 17.5429 18.2071C16.4444 17.1086 14.6878 16 12 16C9.31219 16 7.55563 17.1086 6.45713 18.2071C5.90167 18.7626 5.51244 19.3188 5.26377 19.7332C5.13985 19.9398 5.05214 20.1089 4.99719 20.2221C4.96974 20.2786 4.95059 20.3209 4.93926 20.3466C4.9336 20.3594 4.92992 20.368 4.92814 20.3723ZM4.92814 20.3723C4.92726 20.3744 4.92684 20.3755 4.9269 20.3754L4.92735 20.3743L4.92814 20.3723ZM19.0723 20.3733C19.0722 20.373 19.0724 20.3735 19.0723 20.3733V20.3733Z"
      fill="#000"
    />
  </svg>
</template>
