"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetNames = void 0;
var vue_1 = require("vue");
var useSetNames = function (names) {
    var namesRegex = /.{2,}$/;
    var firstName = (0, vue_1.ref)((names === null || names === void 0 ? void 0 : names.firstName) || '');
    var lastName = (0, vue_1.ref)((names === null || names === void 0 ? void 0 : names.lastName) || '');
    var firstNameRef = (0, vue_1.ref)(null);
    var lastNameRef = (0, vue_1.ref)(null);
    var isFirstNameValid = (0, vue_1.computed)(function () { return firstName.value && namesRegex.test(firstName.value); });
    var isLastNameValid = (0, vue_1.computed)(function () { return lastName.value && namesRegex.test(lastName.value); });
    var firstNameError = (0, vue_1.computed)(function () {
        if (!firstName.value) {
            return '';
        }
        return isFirstNameValid.value ? '' : 'profile_page.contact-info-first-name-invalid';
    });
    var lastNameError = (0, vue_1.computed)(function () {
        if (!lastName.value) {
            return '';
        }
        return isLastNameValid.value ? '' : 'profile_page.contact-info-last-name-invalid';
    });
    return {
        firstName: firstName,
        lastName: lastName,
        firstNameRef: firstNameRef,
        lastNameRef: lastNameRef,
        isFirstNameValid: isFirstNameValid,
        isLastNameValid: isLastNameValid,
        firstNameError: firstNameError,
        lastNameError: lastNameError,
    };
};
exports.useSetNames = useSetNames;
