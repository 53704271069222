"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUserDetailsStore = void 0;
var pinia_1 = require("pinia");
exports.useUserDetailsStore = (0, pinia_1.defineStore)({
    id: 'user-details',
    state: function () { return ({
        email: '',
        exists: false,
        loginMethods: new Set([]),
        loginStatus: '',
        names: {},
    }); },
});
