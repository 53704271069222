"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var APP_URL = process.env.VUE_APP_URL || 'localhost';
var getEnvironment = function (hostName) {
    if (hostName.includes(APP_URL)) {
        if (hostName.includes('stage'))
            return 'stage';
        if (!hostName.includes('dev') && !hostName.includes('stage'))
            return 'prod';
    }
    return 'dev';
};
exports.default = getEnvironment;
