<template>
  <svg aria-hidden="true" viewBox="0 0 375 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M375 6.71933C316.073 26.3622 253.029 37.0001 187.5 37.0001C121.971 37 58.9269 26.3622 -2.93712e-07 6.71934L0 -1.63918e-05L375 0L375 6.71933Z"
      fill="#0D234B"
    ></path>
  </svg>
</template>
