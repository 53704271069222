"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _withScopeId = function (n) { return ((0, vue_2.pushScopeId)("data-v-b92d9296"), n = n(), (0, vue_2.popScopeId)(), n); };
var _hoisted_1 = { class: "content" };
var _hoisted_2 = { class: "error-description" };
var _hoisted_3 = { class: "error-description" };
var _hoisted_4 = { class: "error-description" };
var _hoisted_5 = { class: "error-description" };
var _hoisted_6 = { class: "error-description" };
var _hoisted_7 = { class: "error-description" };
var _hoisted_8 = { class: "error-description" };
var _hoisted_9 = { class: "error-description" };
var _hoisted_10 = { class: "error-description" };
var _hoisted_11 = { class: "error-description" };
var _hoisted_12 = { class: "error-description" };
var _hoisted_13 = { class: "error-description" };
var oidc_context_1 = require("@/stores/oidc-context");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'ErrorModal',
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        statusCode: {
            type: String,
            required: true,
        },
    },
    emits: ['close'],
    setup: function (__props, _a) {
        var emits = _a.emit;
        var props = __props;
        var oidcContextStore = (0, oidc_context_1.useOidcContextStore)();
        var isLoading = (0, vue_3.ref)(false);
        var isIncorrectCreds = (0, vue_3.computed)(function () { return props.statusCode === '401'; });
        var isAccountLocked = (0, vue_3.computed)(function () { return props.statusCode === '403.10'; });
        var isPasswordChangeFailed = (0, vue_3.computed)(function () { return props.statusCode === '403.30'; });
        var isLoginRequired = (0, vue_3.computed)(function () { return props.statusCode === '403.50'; });
        var isAccountNotFound = (0, vue_3.computed)(function () { return props.statusCode === '404'; });
        var isAlreadyExisting = (0, vue_3.computed)(function () { return props.statusCode === '409'; });
        var isCodeInvalid = (0, vue_3.computed)(function () { return props.statusCode === '422'; });
        var isCodeExpired = (0, vue_3.computed)(function () { return props.statusCode === '422.10'; });
        var isDelegateNotFound = (0, vue_3.computed)(function () { return props.statusCode === '422.20'; });
        var isTemporarilyLocked = (0, vue_3.computed)(function () { return props.statusCode === '429.10'; });
        var isLoginInvalid = (0, vue_3.computed)(function () { return props.statusCode === '422.30' || props.statusCode === '422.40'; });
        var close = function () {
            emits('close');
        };
        var retry = function () {
            isLoading.value = true;
            if (oidcContextStore.authorization_url) {
                window.location.href = oidcContextStore.authorization_url;
                return;
            }
            window.location.href = '/';
        };
        (0, vue_3.watch)(function () { return props.open; }, function (value) {
            // TODO: Should focus ok button when shown
            if (value) {
                requestAnimationFrame(function () {
                    var button = document.querySelector('.confirm-button button');
                    button === null || button === void 0 ? void 0 : button.focus();
                });
            }
        });
        return function (_ctx, _cache) {
            var _component_pn_button = (0, vue_2.resolveComponent)("pn-button");
            var _component_pn_modal = (0, vue_2.resolveComponent)("pn-modal");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_pn_modal, {
                open: __props.open,
                onClose: close
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createElementVNode)("div", _hoisted_1, [
                        (isIncorrectCreds.value)
                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 0 }, [
                                (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.email-password-combo-header')), 1),
                                (0, vue_2.createElementVNode)("div", null, [
                                    (0, vue_2.createElementVNode)("p", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.email-password-combo-text')), 1),
                                    (0, vue_2.createVNode)(_component_pn_button, {
                                        class: "confirm-button",
                                        onClick: close
                                    }, {
                                        default: (0, vue_2.withCtx)(function () { return [
                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                        ]; }),
                                        _: 1
                                    })
                                ])
                            ], 64))
                            : (isAccountLocked.value)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 1 }, [
                                    (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.locked-account-header')), 1),
                                    (0, vue_2.createElementVNode)("div", null, [
                                        (0, vue_2.createElementVNode)("p", _hoisted_3, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.locked-account-text')), 1),
                                        (0, vue_2.createVNode)(_component_pn_button, {
                                            class: "confirm-button",
                                            onClick: close
                                        }, {
                                            default: (0, vue_2.withCtx)(function () { return [
                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                            ]; }),
                                            _: 1
                                        })
                                    ])
                                ], 64))
                                : (isPasswordChangeFailed.value)
                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 2 }, [
                                        (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.token-invalid-header')), 1),
                                        (0, vue_2.createElementVNode)("div", null, [
                                            (0, vue_2.createElementVNode)("p", _hoisted_4, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.token-invalid-text')), 1),
                                            (0, vue_2.createVNode)(_component_pn_button, {
                                                class: "confirm-button",
                                                onClick: close
                                            }, {
                                                default: (0, vue_2.withCtx)(function () { return [
                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                ]; }),
                                                _: 1
                                            })
                                        ])
                                    ], 64))
                                    : (isLoginRequired.value)
                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 3 }, [
                                            (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('profile_page.login-required')), 1),
                                            (0, vue_2.createElementVNode)("div", null, [
                                                (0, vue_2.createElementVNode)("p", _hoisted_5, (0, vue_2.toDisplayString)(_ctx.$t('profile_page.login-required-subtitle')), 1),
                                                (0, vue_2.createVNode)(_component_pn_button, {
                                                    class: "confirm-button",
                                                    onClick: close
                                                }, {
                                                    default: (0, vue_2.withCtx)(function () { return [
                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                    ]; }),
                                                    _: 1
                                                })
                                            ])
                                        ], 64))
                                        : (isAccountNotFound.value)
                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 4 }, [
                                                (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.account-missing-header')), 1),
                                                (0, vue_2.createElementVNode)("div", null, [
                                                    (0, vue_2.createElementVNode)("p", _hoisted_6, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.account-missing-text')), 1),
                                                    (0, vue_2.createVNode)(_component_pn_button, {
                                                        class: "confirm-button",
                                                        onClick: close
                                                    }, {
                                                        default: (0, vue_2.withCtx)(function () { return [
                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                        ]; }),
                                                        _: 1
                                                    })
                                                ])
                                            ], 64))
                                            : (isAlreadyExisting.value)
                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 5 }, [
                                                    (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.account-already-exists-header')), 1),
                                                    (0, vue_2.createElementVNode)("div", null, [
                                                        (0, vue_2.createElementVNode)("p", _hoisted_7, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.account-already-exists-text')), 1),
                                                        (0, vue_2.createVNode)(_component_pn_button, {
                                                            class: "confirm-button",
                                                            onClick: close
                                                        }, {
                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                            ]; }),
                                                            _: 1
                                                        })
                                                    ])
                                                ], 64))
                                                : (isCodeInvalid.value)
                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 6 }, [
                                                        (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.otp-invalid-header')), 1),
                                                        (0, vue_2.createElementVNode)("div", null, [
                                                            (0, vue_2.createElementVNode)("p", _hoisted_8, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.otp-invalid-text')), 1),
                                                            (0, vue_2.createVNode)(_component_pn_button, {
                                                                class: "confirm-button",
                                                                onClick: close
                                                            }, {
                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                                ]; }),
                                                                _: 1
                                                            })
                                                        ])
                                                    ], 64))
                                                    : (isCodeExpired.value)
                                                        ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 7 }, [
                                                            (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.otp-expired-header')), 1),
                                                            (0, vue_2.createElementVNode)("div", null, [
                                                                (0, vue_2.createElementVNode)("p", _hoisted_9, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.otp-expired-text')), 1),
                                                                (0, vue_2.createVNode)(_component_pn_button, {
                                                                    class: "confirm-button",
                                                                    onClick: close
                                                                }, {
                                                                    default: (0, vue_2.withCtx)(function () { return [
                                                                        (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                                    ]; }),
                                                                    _: 1
                                                                })
                                                            ])
                                                        ], 64))
                                                        : (isDelegateNotFound.value)
                                                            ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 8 }, [
                                                                (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.delegate-not-found-header')), 1),
                                                                (0, vue_2.createElementVNode)("div", null, [
                                                                    (0, vue_2.createElementVNode)("p", _hoisted_10, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.delegate-not-found-text')), 1),
                                                                    (0, vue_2.createVNode)(_component_pn_button, {
                                                                        class: "confirm-button",
                                                                        onClick: close
                                                                    }, {
                                                                        default: (0, vue_2.withCtx)(function () { return [
                                                                            (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                                        ]; }),
                                                                        _: 1
                                                                    })
                                                                ])
                                                            ], 64))
                                                            : (isTemporarilyLocked.value)
                                                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 9 }, [
                                                                    (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-limit-reached-header')), 1),
                                                                    (0, vue_2.createElementVNode)("div", null, [
                                                                        (0, vue_2.createElementVNode)("p", _hoisted_11, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-limit-reached-text')), 1),
                                                                        (0, vue_2.createVNode)(_component_pn_button, {
                                                                            class: "confirm-button",
                                                                            onClick: close
                                                                        }, {
                                                                            default: (0, vue_2.withCtx)(function () { return [
                                                                                (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                                            ]; }),
                                                                            _: 1
                                                                        })
                                                                    ])
                                                                ], 64))
                                                                : (isLoginInvalid.value)
                                                                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 10 }, [
                                                                        (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.invalid-login-session-header')), 1),
                                                                        (0, vue_2.createElementVNode)("div", null, [
                                                                            (0, vue_2.createElementVNode)("p", _hoisted_12, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.invalid-login-session-text')), 1),
                                                                            (0, vue_2.createVNode)(_component_pn_button, {
                                                                                loading: isLoading.value,
                                                                                class: "confirm-button",
                                                                                onClick: retry
                                                                            }, {
                                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.try-again')), 1)
                                                                                ]; }),
                                                                                _: 1
                                                                            }, 8, ["loading"])
                                                                        ])
                                                                    ], 64))
                                                                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)(vue_2.Fragment, { key: 11 }, [
                                                                        (0, vue_2.createElementVNode)("h1", null, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.common-header')), 1),
                                                                        (0, vue_2.createElementVNode)("div", null, [
                                                                            (0, vue_2.createElementVNode)("p", _hoisted_13, (0, vue_2.toDisplayString)(_ctx.$t('error_modal.server-error')), 1),
                                                                            (0, vue_2.createVNode)(_component_pn_button, {
                                                                                class: "confirm-button",
                                                                                onClick: close
                                                                            }, {
                                                                                default: (0, vue_2.withCtx)(function () { return [
                                                                                    (0, vue_2.createTextVNode)((0, vue_2.toDisplayString)(_ctx.$t('error_modal.action-ok')), 1)
                                                                                ]; }),
                                                                                _: 1
                                                                            })
                                                                        ])
                                                                    ], 64))
                    ])
                ]; }),
                _: 1
            }, 8, ["open"]));
        };
    }
});
