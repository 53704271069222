<template>
  <svg width="47" height="45" viewBox="0 0 47 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9962 23.4038L16.9116 11.3743C16.1571 11.3743 14.8656 11.3743 14.8656 11.3743C13.9079 11.3743 12.6744 10.8394 12.3116 9.8562C12.1956 9.52365 11.9198 8.38143 13.5016 7.26813C14.0675 6.87775 14.4303 6.444 14.5028 6.11146C14.5754 5.76445 14.4883 5.46082 14.2416 5.22949C13.8933 4.89694 13.2113 4.70898 12.3407 4.70898C10.8751 4.70898 9.84477 5.54758 9.74319 6.15483C9.67064 6.60304 10.0189 6.9645 10.3236 7.19584C11.2378 7.87539 11.4555 8.85856 10.8896 9.7839C10.3091 10.7382 9.04666 11.3599 7.69713 11.3743C7.69713 11.3743 6.36212 11.3743 5.60754 11.3743C5.43341 12.5455 2.58925 30.5029 2.37158 31.9198H13.7047C13.8063 31.2836 14.3287 27.8859 15.0397 23.4038H14.9962Z"
      fill="#479CBE"
    />
    <path
      d="M30.175 0.848633H18.6388L17.1006 10.5792H19.0596C20.1334 10.5792 21.1492 10.0876 21.5845 9.37913C21.7296 9.14779 21.7877 8.94537 21.7877 8.75741C21.7877 8.35258 21.5119 8.04895 21.2362 7.84653C20.4817 7.28265 20.322 6.68985 20.322 6.27056C20.322 6.18381 20.322 6.11151 20.3365 6.03922C20.4962 5.01267 21.8892 3.89937 23.7321 3.89937C24.835 3.89937 25.6766 4.15962 26.1845 4.63675C26.6343 5.05605 26.8085 5.64884 26.6779 6.27056C26.5183 7.00794 25.7782 7.61519 25.3574 7.91882C24.24 8.69958 24.3851 9.37913 24.4577 9.58155C24.6899 10.1888 25.575 10.5792 26.2571 10.5792H29.2318C29.2318 10.5792 29.2318 10.5792 29.2318 10.5936C33.2949 10.6226 35.4716 12.4877 34.7895 16.8252C34.1511 20.8591 31.0457 22.5942 27.3454 22.6231L25.8798 31.9343H28.0419C37.1694 31.9343 44.628 26.0931 46.0936 16.8686C47.9075 5.41751 40.623 0.848633 30.175 0.848633Z"
      fill="#00A5C3"
    />
    <path
      d="M30.175 0.848633H18.6388L17.1006 10.5792H19.0596C20.1334 10.5792 21.1492 10.0876 21.5845 9.37913C21.7296 9.14779 21.7877 8.94537 21.7877 8.75741C21.7877 8.35258 21.5119 8.04895 21.2362 7.84653C20.4817 7.28265 20.322 6.68985 20.322 6.27056C20.322 6.18381 20.322 6.11151 20.3365 6.03922C20.4962 5.01267 21.8892 3.89937 23.7321 3.89937C24.835 3.89937 25.6766 4.15962 26.1845 4.63675C26.6343 5.05605 26.8085 5.64884 26.6779 6.27056C26.5183 7.00794 25.7782 7.61519 25.3574 7.91882C24.24 8.69958 24.3851 9.37913 24.4577 9.58155C24.6899 10.1888 25.575 10.5792 26.2571 10.5792H29.2318C29.2318 10.5792 29.2318 10.5792 29.2318 10.5936C33.2949 10.6226 35.4716 12.4877 34.7895 16.8252C34.1511 20.8591 31.0457 22.5942 27.3454 22.6231L25.8798 31.9343H28.0419C37.1694 31.9343 44.628 26.0931 46.0936 16.8686C47.9075 5.41751 40.623 0.848633 30.175 0.848633Z"
      fill="#235971"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.76225 36.0693H6.39127C8.36477 36.0693 8.84363 37.067 8.69852 37.9779C8.58244 38.7152 8.07455 39.2647 7.20389 39.6261C8.30673 40.0454 8.74206 40.696 8.58244 41.7226C8.37928 43.0094 7.26193 43.9637 5.79632 43.9637H0.528809L1.76225 36.0693ZM4.82408 39.337C5.72376 39.337 6.14458 38.8598 6.23165 38.2959C6.31872 37.6887 6.043 37.2694 5.14332 37.2694H4.34521L4.02597 39.337H4.82408ZM5.92692 41.6069C5.79632 42.3732 5.25941 42.7492 4.3307 42.7492V42.7636H3.48906L3.83733 40.5515H4.75152C5.65121 40.5515 6.02849 40.9418 5.92692 41.6069Z"
      fill="#235971"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.9938 43.4577C13.284 43.9782 13.8645 44.1083 15.0689 44.0216L15.7654 42.4022C15.0834 42.3878 15.0544 42.1998 15.1559 41.5925L15.4317 39.8575C15.6638 38.3249 14.4159 37.7466 13.0083 37.7466C11.9055 37.7466 10.9477 38.0791 9.961 38.7876L9.68529 40.5804C10.8317 39.7997 11.4992 39.5394 12.2973 39.5394C12.9793 39.5394 13.197 39.7419 13.1389 40.2913C12.8122 40.5127 12.2559 40.6519 11.6741 40.7976C10.9641 40.9754 10.2161 41.1626 9.80137 41.5202C9.45311 41.8239 9.23544 42.1853 9.1774 42.5613C9.0468 43.342 9.52567 44.0505 10.8897 44.0505C11.6443 44.0505 12.3553 43.8481 12.9938 43.4577ZM12.1917 41.6078C12.4758 41.5251 12.76 41.4424 12.9793 41.3034L12.8487 42.1709C12.8052 42.4456 12.7906 42.5902 12.8197 42.7347C12.4424 42.995 11.978 43.154 11.6007 43.154C11.2089 43.154 10.9623 42.8649 11.0203 42.4745C11.1061 41.9239 11.6485 41.766 12.1917 41.6078Z"
      fill="#235971"
    />
    <path
      d="M17.3182 37.8478H19.727L19.5965 38.643C20.3655 37.9924 20.946 37.7466 21.7006 37.7466C23.0501 37.7466 23.6741 38.5707 23.4564 39.9154L22.8324 43.9493H20.4236L20.946 40.6094C21.0476 40.0021 20.8589 39.7129 20.3946 39.7129C20.0173 39.7129 19.669 39.9154 19.3353 40.3636L18.7838 43.9348H16.375L17.3182 37.8478Z"
      fill="#235971"
    />
    <path
      d="M25.3282 36.0693H27.737L27.1275 39.9442L29.4348 37.8477H32.4096L29.4493 40.4503L31.8291 43.9492H28.7963L26.9679 41.1298H26.9389L26.5036 43.9492H24.0947L25.3282 36.0693Z"
      fill="#235971"
    />
    <path d="M33.8608 36.0693H36.6324L35.4135 43.9492H32.6418L33.8608 36.0693Z" fill="#479CBE" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.9435 36.0693H37.982L36.7485 43.9492H41.2179C43.9025 43.9492 45.3246 41.9106 45.6003 40.1177C45.8905 38.2815 45.0053 36.0693 41.9435 36.0693ZM42.8577 40.0021C42.64 41.4045 41.8999 42.0696 40.5504 42.0696H39.8249L40.4634 37.9345H41.2034C42.698 37.9345 43.0173 38.961 42.8577 40.0021Z"
      fill="#479CBE"
    />
  </svg>
</template>
