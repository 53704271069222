<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="37" viewBox="0 0 37 212" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.966 0C27.4681 31.7207 36.7949 67.7712 36.7949 106C36.7949 144.229 27.4681 180.279 10.966 212H0V0L10.966 0Z"
      fill="#0D234B"
    ></path>
  </svg>
</template>
