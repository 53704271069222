<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.7071 11.6132C20.0976 12.0037 20.0976 12.6369 19.7071 13.0274L13.7071 19.0274C13.3166 19.4179 12.6834 19.4179 12.2929 19.0274C11.9024 18.6369 11.9024 18.0037 12.2929 17.6132L16.5858 13.3203L8 13.3203C7.44771 13.3203 7 12.8726 7 12.3203C7 11.768 7.44771 11.3203 8 11.3203L16.5858 11.3203L12.2929 7.02742C11.9024 6.6369 11.9024 6.00373 12.2929 5.61321C12.6834 5.22268 13.3166 5.22268 13.7071 5.61321L19.7071 11.6132ZM5.01 13.3203L5 13.3203C4.44771 13.3203 4 12.8726 4 12.3203C4 11.768 4.44771 11.3203 5 11.3203L5.01 11.3203C5.56229 11.3203 6.01 11.768 6.01 12.3203C6.01 12.8726 5.56229 13.3203 5.01 13.3203Z"
      fill="currentColor"
    />
  </svg>
</template>
