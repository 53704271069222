<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 2.4rem;
  padding: 0 1rem;
}
</style>
