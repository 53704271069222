"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Divider',
    props: {
        vertical: {
            type: Boolean,
            default: false,
            required: false,
        },
        width: {
            type: String,
            default: '100%',
            required: false,
        },
        height: {
            type: String,
            default: '0.1rem',
            required: false,
        },
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: "divider",
                style: (0, vue_2.normalizeStyle)({
                    width: __props.vertical ? __props.height : __props.width,
                    height: __props.vertical ? __props.width : __props.height,
                })
            }, null, 4));
        };
    }
});
