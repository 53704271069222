<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 416 416" fill="none" class="scale-mobile">
    <g clip-path="url(#clip-mobile-check)">
      <path
        d="M0 208C0 93.1248 93.1248 0 208 0C322.875 0 416 93.1248 416 208C416 322.875 322.875 416 208 416C93.1248 416 0 322.875 0 208Z"
        fill="#8EDDF9"
      ></path>
      <path d="M305.333 211.789L283.087 231.383L266.529 231.51L271.206 209.387L305.333 211.789Z" fill="#FBC2C1"></path>
      <path d="M297.623 286.875L276.388 307.102L275.377 284.094L297.623 286.875Z" fill="#FBC2C1"></path>
      <path d="M306.47 251.985L284.224 268.546L268.678 267.282L274.745 247.055L306.47 251.985Z" fill="#FBC2C1"></path>
      <path
        d="M190.692 417.219L202.826 387.511C202.826 387.511 237.964 362.48 254.016 330.496L150.751 330.875L115.107 417.345H190.692V417.219Z"
        fill="#FBC2C1"
      ></path>
      <path
        d="M248.328 150.984L291.555 116.345C298.002 111.162 307.481 112.3 312.411 118.874C316.835 124.689 316.455 132.906 311.273 138.215L248.328 202.689V150.984Z"
        fill="#FBC2C1"
      ></path>
      <path d="M276.389 128.479L284.478 122.031L279.928 170.197L276.389 173.863V128.479Z" fill="#500715"></path>
      <path
        d="M278.538 102.562V89.2884C278.538 81.0713 272.092 74.3711 264.129 74.3711H152.901C144.938 74.3711 138.492 81.0713 138.492 89.2884V102.562H278.538Z"
        fill="black"
      ></path>
      <path
        d="M138.365 307.609V323.285C138.365 331.502 144.811 338.202 152.774 338.202H264.002C271.965 338.202 278.411 331.502 278.411 323.285V307.609H138.365Z"
        fill="black"
      ></path>
      <path d="M138.306 307.586L278.479 307.586L278.479 102.536L138.306 102.536L138.306 307.586Z" fill="white"></path>
      <path
        d="M265.264 188.776L290.67 182.455C300.529 180.053 310.135 187.132 310.767 197.246L310.893 198.636C311.272 206.727 305.711 213.933 297.748 215.45L272.342 220.254C262.736 222.15 253.636 215.071 253.13 205.21C252.498 197.625 257.68 190.672 265.264 188.776Z"
        fill="#FBC2C1"
      ></path>
      <path
        d="M272.217 222.029C271.585 222.029 270.953 221.523 270.701 220.891C270.448 220.006 270.953 219.121 271.712 218.869L288.775 213.812C289.66 213.559 290.545 214.065 290.797 214.823C291.05 215.708 290.545 216.593 289.786 216.846L272.723 221.903C272.47 221.903 272.344 222.029 272.217 222.029Z"
        fill="#500715"
      ></path>
      <path
        d="M270.827 227.463L294.969 223.544C304.322 222.027 312.79 229.486 312.538 238.967V240.358C312.285 247.943 306.471 254.138 299.013 255.022L274.872 257.551C265.771 258.562 257.935 251.23 258.188 242.001C258.314 234.669 263.749 228.601 270.827 227.463Z"
        fill="#FBC2C1"
      ></path>
      <path
        d="M264.76 267.913L285.489 264.626C293.578 263.362 300.783 269.683 300.53 277.9V279.038C300.277 285.612 295.348 290.921 288.901 291.553L268.173 293.702C260.336 294.461 253.637 288.267 253.763 280.429C254.016 274.234 258.567 268.925 264.76 267.913Z"
        fill="#FBC2C1"
      ></path>
      <path
        d="M62.4004 417.215H133.94L154.29 359.568C192.461 294.589 138.238 244.148 138.238 244.148V162.482C138.238 159.701 136.974 157.046 134.951 155.15C129.896 150.725 121.806 152.748 119.531 159.069L88.817 241.494C87.4267 245.286 86.6683 249.331 86.7947 253.377L87.8059 345.03C87.9323 351.983 86.5419 358.809 83.8876 365.257L62.4004 417.215Z"
        fill="#FBC2C1"
      ></path>
      <path
        d="M274.872 259.062C274.114 259.062 273.482 258.556 273.355 257.798C273.229 256.913 273.734 256.154 274.619 255.901L290.924 252.488C291.809 252.362 292.567 252.867 292.82 253.752C292.946 254.637 292.441 255.396 291.556 255.648L275.251 259.062C275.125 259.062 274.998 259.062 274.872 259.062Z"
        fill="#500715"
      ></path>
      <path
        d="M277.777 294.334C277.019 294.334 276.26 293.701 276.26 292.943C276.134 292.058 276.766 291.299 277.651 291.173L284.476 290.415C285.361 290.288 286.119 290.92 286.245 291.805C286.372 292.69 285.74 293.449 284.855 293.575L278.03 294.334C277.903 294.334 277.903 294.334 277.777 294.334Z"
        fill="#500715"
      ></path>
      <path
        d="M156.311 355.395C156.059 355.395 155.679 355.269 155.427 355.142C154.668 354.636 154.542 353.625 155.047 352.993C161.62 343.385 163.642 339.213 163.642 339.087C164.022 338.328 164.906 337.949 165.791 338.328C166.549 338.708 166.929 339.593 166.549 340.478C166.423 340.604 164.401 345.029 157.702 354.763C157.323 355.142 156.817 355.395 156.311 355.395Z"
        fill="#500715"
      ></path>
      <path d="M160.23 347.056L209.904 338.207H163.896L160.23 347.056Z" fill="#500715"></path>
      <path
        d="M227.979 288.522H188.67C183.487 288.522 179.316 284.351 179.316 279.167C179.316 273.984 183.487 269.812 188.67 269.812H227.979C233.161 269.812 237.332 273.984 237.332 279.167C237.332 284.351 233.161 288.522 227.979 288.522Z"
        fill="#005D92"
      ></path>
      <path
        d="M222.289 281.578H194.355C193.091 281.578 192.08 280.567 192.08 279.303C192.08 278.039 193.091 277.027 194.355 277.027H222.289C223.553 277.027 224.564 278.039 224.564 279.303C224.69 280.567 223.679 281.578 222.289 281.578Z"
        fill="#00A0D6"
      ></path>
      <path
        d="M242.638 209.386H181.589C180.704 209.386 180.072 208.627 180.072 207.869C180.072 207.11 180.831 206.352 181.589 206.352H242.638C243.523 206.352 244.155 207.11 244.155 207.869C244.155 208.627 243.523 209.386 242.638 209.386Z"
        fill="#F3F2F2"
      ></path>
      <path
        d="M239.227 219.757H178.81C177.925 219.757 177.293 218.998 177.293 218.24C177.293 217.481 178.051 216.723 178.81 216.723H239.227C240.112 216.723 240.744 217.481 240.744 218.24C240.744 218.998 240.112 219.757 239.227 219.757Z"
        fill="#F3F2F2"
      ></path>
      <path
        d="M224.059 230.116H193.851C192.966 230.116 192.334 229.358 192.334 228.599C192.334 227.841 193.092 227.082 193.851 227.082H224.059C224.944 227.082 225.576 227.841 225.576 228.599C225.576 229.358 224.944 230.116 224.059 230.116Z"
        fill="#F3F2F2"
      ></path>
      <path
        d="M209.018 188.273C193.725 188.273 181.338 175.885 181.338 160.588C181.338 145.291 193.725 132.902 209.018 132.902C224.312 132.902 236.699 145.291 236.699 160.588C236.699 175.885 224.312 188.273 209.018 188.273Z"
        fill="#5EC584"
      ></path>
      <path
        d="M207.881 171.833C207.501 171.833 207.122 171.707 206.743 171.328L195.999 160.582C195.367 159.95 195.367 158.939 195.999 158.307C196.631 157.675 197.642 157.675 198.274 158.307L207.881 167.914L225.197 150.595C225.829 149.963 226.84 149.963 227.472 150.595C228.104 151.227 228.104 152.239 227.472 152.871L209.018 171.328C208.765 171.581 208.26 171.833 207.881 171.833Z"
        fill="#005E41"
      ></path>
      <path
        d="M175.272 209.386C174.261 209.386 173.629 208.627 173.629 207.869C173.629 207.11 174.387 206.352 175.146 206.352C175.904 206.352 176.789 207.11 176.789 207.869C176.789 208.627 176.157 209.386 175.272 209.386Z"
        fill="#F3F2F2"
      ></path>
    </g>
    <defs>
      <clipPath id="clip-mobile-check">
        <path
          d="M0 208C0 93.1248 93.1248 0 208 0C322.875 0 416 93.1248 416 208C416 322.875 322.875 416 208 416C93.1248 416 0 322.875 0 208Z"
          fill="white"
        ></path>
      </clipPath>
    </defs>
  </svg>
</template>
