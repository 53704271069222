<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 11.25C16.0629 11.25 11.25 16.0629 11.25 22C11.25 27.9371 16.0629 32.75 22 32.75C27.9371 32.75 32.75 27.9371 32.75 22C32.75 16.0629 27.9371 11.25 22 11.25ZM8.75 22C8.75 14.6822 14.6822 8.75 22 8.75C29.3178 8.75 35.25 14.6822 35.25 22C35.25 25.2088 34.1094 28.1511 32.2115 30.4437L37.3839 35.6161C37.872 36.1043 37.872 36.8957 37.3839 37.3839C36.8957 37.872 36.1043 37.872 35.6161 37.3839L30.4437 32.2115C28.1511 34.1094 25.2088 35.25 22 35.25C14.6822 35.25 8.75 29.3178 8.75 22ZM17.1136 18.9674C18.1304 17.3333 19.9392 16.25 22 16.25C22.6904 16.25 23.25 15.6904 23.25 15C23.25 14.3096 22.6904 13.75 22 13.75C19.0398 13.75 16.4449 15.3099 14.991 17.6467C14.6262 18.2328 14.8058 19.0037 15.3919 19.3684C15.9781 19.7331 16.7489 19.5536 17.1136 18.9674ZM16.25 21.9993C16.2496 21.3089 15.6896 20.7496 14.9993 20.75C14.3089 20.7504 13.7496 21.3104 13.75 22.0007L13.75 22.0107C13.7504 22.7011 14.3104 23.2604 15.0007 23.26C15.6911 23.2596 16.2504 22.6996 16.25 22.0093L16.25 21.9993Z"
      fill="#005D92"
    ></path>
  </svg>
</template>
