"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Card',
    props: {
        maxWidth: {
            type: String,
            required: false,
            default: '51.6rem',
        },
        noPadding: {
            type: Boolean,
        },
    },
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["card", { 'no-padding': __props.noPadding }]),
                style: (0, vue_2.normalizeStyle)({
                    maxWidth: __props.maxWidth,
                })
            }, [
                (0, vue_2.renderSlot)(_ctx.$slots, "default")
            ], 6));
        };
    }
});
