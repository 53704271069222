"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue3_cookies_1 = require("vue3-cookies");
var ttl = '6m'; // Set to 6 months
var useCookies = function () {
    var cookies = (0, vue3_cookies_1.useCookies)().cookies;
    var set = function (key, value) {
        cookies.set(key, value, ttl, '/', '', true, 'strict');
    };
    return { get: cookies.get, set: set };
};
exports.default = useCookies;
