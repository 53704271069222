"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var icons_1 = require("pn-design-assets/pn-assets/icons");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Tabs',
    props: {
        tab: {
            type: String,
        },
    },
    emits: ['tabchange'],
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_pn_icon = (0, vue_2.resolveComponent)("pn-icon");
            var _component_pn_tab = (0, vue_2.resolveComponent)("pn-tab");
            var _component_pn_tablist = (0, vue_2.resolveComponent)("pn-tablist");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_pn_tablist, {
                value: __props.tab,
                onTabchange: _cache[0] || (_cache[0] = function ($event) { return (_ctx.$emit('tabchange', $event)); })
            }, {
                default: (0, vue_2.withCtx)(function () { return [
                    (0, vue_2.createVNode)(_component_pn_tab, {
                        value: "about-account",
                        label: _ctx.$t('about_postnord_account_page.tab-about-postnord-account')
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_pn_icon, { icon: (0, vue_2.unref)(icons_1.user) }, null, 8, ["icon"])
                        ]; }),
                        _: 1
                    }, 8, ["label"]),
                    (0, vue_2.createVNode)(_component_pn_tab, {
                        value: "protect-account",
                        label: _ctx.$t('protect_postnord_account_page.tab-protect-postnord-accout')
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_pn_icon, { icon: (0, vue_2.unref)(icons_1.padlock) }, null, 8, ["icon"])
                        ]; }),
                        _: 1
                    }, 8, ["label"]),
                    (0, vue_2.createVNode)(_component_pn_tab, {
                        value: "terms-conditions",
                        label: _ctx.$t('terms_and_conditions_page.tab-terms-and-conditions')
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_pn_icon, { icon: (0, vue_2.unref)(icons_1.receipt) }, null, 8, ["icon"])
                        ]; }),
                        _: 1
                    }, 8, ["label"]),
                    (0, vue_2.createVNode)(_component_pn_tab, {
                        value: "privacy-policies",
                        label: _ctx.$t('privacy_policies.tab-privacy-policies')
                    }, {
                        default: (0, vue_2.withCtx)(function () { return [
                            (0, vue_2.createVNode)(_component_pn_icon, { icon: (0, vue_2.unref)(icons_1.globe) }, null, 8, ["icon"])
                        ]; }),
                        _: 1
                    }, 8, ["label"])
                ]; }),
                _: 1
            }, 8, ["value"]));
        };
    }
});
