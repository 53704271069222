"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'Input',
    setup: function (__props, _a) {
        var __expose = _a.expose;
        __expose({
            shake: function () {
                shakeInput.value = true;
                setTimeout(function () { return (shakeInput.value = false); }, 1000);
            },
        });
        var shakeInput = (0, vue_3.ref)(false);
        return function (_ctx, _cache) {
            var _component_pn_input = (0, vue_2.resolveComponent)("pn-input");
            return ((0, vue_2.openBlock)(), (0, vue_2.createBlock)(_component_pn_input, {
                class: (0, vue_2.normalizeClass)({ shake: shakeInput.value })
            }, null, 8, ["class"]));
        };
    }
});
