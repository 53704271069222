"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePolicyLink = exports.policyLinks = void 0;
var oidc_context_1 = require("@/stores/oidc-context");
exports.policyLinks = {
    da: 'https://www.postnord.dk/personlige-oplysninger?showOnetrust=false',
    fi: 'https://www.postnord.fi/yksityisyydensuoja?showOnetrust=false',
    no: 'https://www.postnord.no/personvernerklaering?showOnetrust=false',
    sv: 'https://www.postnord.se/integritetspolicy?showOnetrust=false',
    en: 'https://www.postnord.com/privacy-policy?showOnetrust=false',
};
var usePolicyLink = function () {
    var oidcContextStore = (0, oidc_context_1.useOidcContextStore)();
    var link = exports.policyLinks[oidcContextStore.language];
    return link || exports.policyLinks.en;
};
exports.usePolicyLink = usePolicyLink;
