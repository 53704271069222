<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#E0F8FF" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.2815 20.2613C16.2815 19.0299 15.5068 18.1917 14.3533 18.1917C13.1833 18.1917 12.4081 19.0299 12.4081 20.2613C12.4081 21.4194 13.1998 22.2765 14.3533 22.2765C15.5245 22.2765 16.2815 21.4194 16.2815 20.2613ZM15.1628 25.4676C18.106 25.4676 20 23.1153 20 20.2613C20 17.3358 18.3475 15 15.4208 15C13.4243 15 12.5117 16.2839 12.5117 16.2839V15.3205H9V28H12.5117V24.5409C12.5117 24.5409 13.3553 25.4676 15.1628 25.4676Z"
      fill="#00A0D6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.4103 15C25.3263 15 24.5729 16.263 24.5729 16.263V15.3153H21V25H24.5729V20.0346C24.5729 18.807 25.029 18.2449 26.1313 18.2449C27.2347 18.2449 27.3929 19.1577 27.3929 20.3156V25H31V19.052C31 16.3855 29.7227 15 27.4103 15Z"
      fill="#00A0D6"
    />
  </svg>
</template>
