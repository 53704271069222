"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var useIsMobile = function () {
    var isMobile = (0, vue_1.ref)(window.innerWidth < 536);
    var updateIsMobile = function () {
        isMobile.value = window.innerWidth < 536;
    };
    (0, vue_1.onMounted)(function () {
        window.addEventListener('resize', updateIsMobile);
        updateIsMobile(); // initial update
    });
    (0, vue_1.onUnmounted)(function () {
        window.removeEventListener('resize', updateIsMobile);
    });
    return isMobile;
};
exports.default = useIsMobile;
