<template>
  <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 560" fill="none" class="scale left">
    <g clip-path="url(#clip-hand-lock)">
      <g clip-path="url(#clip1)">
        <path
          d="M674.2 560.3H721.1C732 560.3 740.8 551.5 740.8 540.6V366.2C740.8 355.3 732 346.5 721.1 346.5H674.2V560.3Z"
          fill="#0D234B"
        ></path>
        <path
          d="M696.3 560.3H438.7C427.8 560.3 419 551.5 419 540.6V366.2C419 355.3 427.8 346.5 438.7 346.5H676.6C687.5 346.5 696.3 355.3 696.3 366.2V560.3Z"
          fill="#005D92"
        ></path>
        <path
          d="M666.4 407.3C666.4 419.4 656.6 429.3 644.4 429.3H611.7C599.6 429.3 589.7 419.5 589.7 407.3C589.7 395.2 599.5 385.3 611.7 385.3H644.4C656.6 385.4 666.4 395.2 666.4 407.3Z"
          fill="#00A0D6"
        ></path>
        <path
          d="M502 458.3C500.4 460 498.9 461.7 497.3 463.4C479.6 482.9 450.9 519.9 445.2 560.3H447.8C453.6 520.7 481.8 484.4 499.2 465.1C500.7 463.4 502.3 461.7 503.8 460.1C511.6 451.8 519.6 443.1 524.6 432.7C532.6 416.2 529.2 400.7 522.3 392.6C515.5 384.6 503.6 380.6 490.7 381.8C478.1 383 465.4 388.5 451.8 398.5C440.1 407.2 429 416.8 419 426.2V429.7C429.4 419.8 441 409.7 453.4 400.6C466.6 390.8 478.9 385.5 491 384.4C503.1 383.2 514.1 386.9 520.4 394.3C526.7 401.7 529.8 416.1 522.3 431.6C517.5 441.7 509.6 450.2 502 458.3Z"
          fill="#0D234B"
        ></path>
        <path
          d="M696.3 478.6C679.6 495.3 662.8 517.5 647.5 537.7C641.5 545.7 635.6 553.4 630.1 560.3H633.4C638.5 553.8 643.9 546.6 649.5 539.2C664.1 519.8 680.2 498.6 696.2 482.2V478.6H696.3Z"
          fill="#0D234B"
        ></path>
        <path
          d="M591.4 359.6C596.7 354.6 602.1 350.2 607.2 346.6H602.9C598.6 349.8 594.1 353.6 589.7 357.7C567.4 378.9 559 401.7 565.5 423.6C570.5 440.6 579.4 452.4 592 458.6C609.3 467.1 633.4 465.2 665.7 452.6C678.1 447.8 688.3 444.5 696.4 442.3V439.6C688.2 441.9 677.6 445.2 664.7 450.2C633.1 462.5 609.6 464.4 593 456.3C581.1 450.5 572.7 439.2 567.9 422.9C561.6 401.7 569.5 380.4 591.4 359.6Z"
          fill="#0D234B"
        ></path>
        <path d="M526.3 242.9L500.7 270.2L480 272.3L483.2 244L526.3 242.9Z" fill="#F06365"></path>
        <path d="M527.5 296.3L499.5 317.4L480 315.9L487.6 290.4L527.5 296.3Z" fill="#F06365"></path>
        <path
          d="M519.7 346.4L512.9 351L487.9 342.1L478.6 356.4L354 342.9L312.6 560.3H440.5L421.7 427.3C500.8 384.9 519.7 346.4 519.7 346.4Z"
          fill="#F06365"
        ></path>
        <path
          d="M369.8 400.3C369.8 400.3 405.3 401.6 429.4 389.1L374.5 372.6C374.5 372.6 374.2 394.4 369.8 400.3Z"
          fill="#500715"
        ></path>
        <path
          d="M355.8 267.5L488.2 132C495.5 124.5 507.6 124.4 514.9 131.9C521.4 138.5 522.1 149 516.5 156.4L440 257.1L355.8 267.5Z"
          fill="#F06365"
        ></path>
        <path
          d="M414.6 390.9C464.306 390.9 504.6 350.606 504.6 300.9C504.6 251.195 464.306 210.9 414.6 210.9C364.894 210.9 324.6 251.195 324.6 300.9C324.6 350.606 364.894 390.9 414.6 390.9Z"
          fill="#FAE3D1"
        ></path>
        <path
          d="M482.9 249.6L463.2 249.4L463.7 195.7C463.9 168.3 441.9 145.9 414.5 145.7C387.1 145.5 364.7 167.5 364.5 194.9L364 248.6L344.3 248.4L344.8 194.7C345 156.5 376.4 125.7 414.6 126C452.8 126.3 483.6 157.7 483.3 195.9L482.9 249.6Z"
          fill="#005D92"
        ></path>
        <path
          d="M414.6 390.9C464.306 390.9 504.6 350.606 504.6 300.9C504.6 251.195 464.306 210.9 414.6 210.9C364.894 210.9 324.6 251.195 324.6 300.9C324.6 350.606 364.894 390.9 414.6 390.9Z"
          fill="#00A0D6"
        ></path>
        <path
          d="M414.6 353.7C443.761 353.7 467.4 330.061 467.4 300.9C467.4 271.739 443.761 248.1 414.6 248.1C385.44 248.1 361.8 271.739 361.8 300.9C361.8 330.061 385.44 353.7 414.6 353.7Z"
          fill="#E0F8FF"
        ></path>
        <path
          d="M427.6 290.4C427.7 283.2 421.9 277.3 414.7 277.3C407.5 277.3 401.7 283 401.6 290.2C401.6 295 404.1 299.2 408 301.5L402.9 324.3L426.1 324.5L421.4 301.4C425.1 299.2 427.6 295.1 427.6 290.4Z"
          fill="#0D234B"
        ></path>
        <path
          d="M473.3 218.9L504.3 208C516.4 203.8 529.3 211.5 531.2 224.2L531.5 226C533 236.1 526.8 245.7 517.1 248.6L485.8 257.7C474 261.1 461.8 253.4 460 241.2C458.4 231.5 464.1 222.2 473.3 218.9Z"
          fill="#F06365"
        ></path>
        <path
          d="M485.7 259C485.2 259 484.7 258.6 484.5 258.1C484.3 257.4 484.7 256.7 485.4 256.6L507.5 250.2C508.2 250 508.9 250.4 509 251.1C509.2 251.8 508.8 252.5 508.1 252.6L486 259C486 258.9 485.9 259 485.7 259Z"
          fill="#500715"
        ></path>
        <path
          d="M482.5 265.7L512.9 260.7C524.7 258.7 535.4 268 535.1 280V281.7C534.9 291.2 527.6 299.1 518.1 300.2L487.8 303.5C476.3 304.8 466.4 295.6 466.7 284.1C466.8 274.9 473.5 267.1 482.5 265.7Z"
          fill="#F06365"
        ></path>
        <path
          d="M481.2 318.8L507.4 322.7C517.6 324.2 523.9 334.6 520.5 344.3L520 345.7C517.3 353.4 509.3 358 501.3 356.4L475.6 351.2C465.9 349.2 460.1 339.1 463.4 329.8C466 322.2 473.5 317.7 481.2 318.8Z"
          fill="#F06365"
        ></path>
        <path
          d="M482.8 305.3C482.2 305.3 481.6 304.8 481.6 304.2C481.5 303.5 482 302.9 482.7 302.8L509.5 299.2C510.2 299.1 510.8 299.6 510.9 300.3C511 301 510.5 301.6 509.8 301.7L483 305.3C482.9 305.3 482.8 305.3 482.8 305.3Z"
          fill="#500715"
        ></path>
        <path
          d="M496 356.5C495.9 356.5 495.8 356.5 495.7 356.5L482.5 353.8C481.8 353.7 481.4 353 481.5 352.3C481.6 351.6 482.3 351.2 483 351.3L496.2 354C496.9 354.1 497.3 354.8 497.2 355.5C497.1 356.1 496.6 356.5 496 356.5Z"
          fill="#500715"
        ></path>
        <path
          d="M374.6 406.6C384.9 327.7 330 297.3 330 297.3L332.9 277.6L348.8 247.8C359.2 233.3 357.7 213.3 345.1 200.6C342.9 198.4 339.3 198.5 337.3 200.8L278.7 267.3C271.1 275.9 267.5 287.3 268.7 298.7L279.6 402.4H279.3L275.2 560.2H341.6L376.7 406.6H374.6Z"
          fill="#F06365"
        ></path>
        <path
          d="M368.2 420C367.9 420 367.6 419.9 367.4 419.7C366.9 419.3 366.8 418.5 367.2 417.9C376.7 406.5 376.1 386.8 376.1 386.6C376.1 385.9 376.6 385.3 377.3 385.3C378 385.3 378.6 385.8 378.6 386.5C378.6 387.3 379.2 407.3 369.1 419.5C369 419.8 368.6 420 368.2 420Z"
          fill="#500715"
        ></path>
        <path
          d="M429.1 474L428.3 473.6L428.7 476.6C435.8 479.9 439.6 483.6 439.6 487.1C439.6 498 404 509.6 351.1 509.6C298.2 509.6 262.5 498 262.5 487.1C262.5 482.9 267.8 478.5 277.4 474.8L277.5 472.1L277 472.3C265.9 476.5 260 481.6 260 487.1C260 501.1 300 512.1 351 512.1C402 512.1 442 501.1 442 487.1C442.1 482.3 437.7 477.9 429.1 474Z"
          fill="#FDEFEE"
        ></path>
        <path
          d="M556.1 499.4H506.1V485.8C506.1 483.8 507.7 482.2 509.7 482.2H552.6C554.6 482.2 556.2 483.8 556.2 485.8V499.4H556.1Z"
          fill="#D3CECB"
        ></path>
        <path d="M556.1 495.1H506.1V560.3H556.1V495.1Z" fill="#D3CECB"></path>
        <path d="M529 482.2H516.3V560.3H529V482.2Z" fill="#F3F2F2"></path>
        <path d="M556.1 520H506.1V522.6H556.1V520Z" fill="#5E554A"></path>
        <path d="M556.1 499.4H506.1V502H556.1V499.4Z" fill="#5E554A"></path>
        <path d="M556.1 540.1H506.1V542.7H556.1V540.1Z" fill="#5E554A"></path>
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M554.9 501.9H551C548.3 501.9 545.9 503.7 545.2 506.3L529.3 560.3H555.2L554.9 501.9Z"
            fill="#5E554A"
          ></path>
        </g>
        <path
          d="M601.3 519.1H551.3V505.5C551.3 503.5 552.9 501.9 554.9 501.9H597.8C599.8 501.9 601.4 503.5 601.4 505.5V519.1H601.3Z"
          fill="#D3CECB"
        ></path>
        <path d="M601.3 516.2H551.3V560.2H601.3V516.2Z" fill="#D3CECB"></path>
        <path d="M574.201 502H561.5V560.3H574.201V502Z" fill="#F3F2F2"></path>
        <path d="M601.3 520H551.3V522.6H601.3V520Z" fill="#5E554A"></path>
        <path d="M601.3 540.101H551.3V542.701H601.3V540.101Z" fill="#5E554A"></path>
        <g opacity="0.2">
          <path
            opacity="0.2"
            d="M600.4 522.5H595.4C593.4 522.5 591.6 523.6 590.8 525.4L574.5 560.2H600.4V522.5Z"
            fill="#5E554A"
          ></path>
        </g>
        <path d="M646.5 538.6H596.5V560.3H646.5V538.6Z" fill="#D3CECB"></path>
        <path
          d="M646.6 539.7H596.6V526.1C596.6 524.1 598.2 522.5 600.2 522.5H643C645 522.5 646.6 524.1 646.6 526.1V539.7Z"
          fill="#D3CECB"
        ></path>
        <path d="M619.4 522.5H606.7V560.3H619.4V522.5Z" fill="#F3F2F2"></path>
        <path d="M646.5 540.1H596.5V542.7H646.5V540.1Z" fill="#5E554A"></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip-hand-lock"><rect width="1000" height="560" fill="white"></rect></clipPath>
      <clipPath id="clip1">
        <rect width="480.8" height="434.3" fill="white" transform="translate(260 126)"></rect>
      </clipPath>
    </defs>
  </svg>
</template>
