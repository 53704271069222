"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_3 = require("vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'LoginBankIdApp',
    props: {
        autoStartToken: {
            type: String,
            required: true,
        },
    },
    setup: function (__props) {
        var props = __props;
        var getAppTriggerData = function (token) { return "bankid:///?autostarttoken=".concat(token, "&redirect=null"); };
        (0, vue_3.onMounted)(function () {
            if (props.autoStartToken) {
                window.location.replace(getAppTriggerData(props.autoStartToken));
            }
        });
        return function (_ctx, _cache) {
            var _component_pn_button = (0, vue_2.resolveComponent)("pn-button");
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(_component_pn_button, { loading: "" })
            ]));
        };
    }
});
