"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANGUAGE_CODES = void 0;
var Language_1 = require("@/enums/Language");
exports.LANGUAGE_CODES = [
    {
        name: Language_1.LanguageCode.Svenska.toUpperCase(),
        code: Language_1.LanguageCode.Svenska,
        text: Language_1.Languages.Svenska,
        numberPrefix: '+46',
    },
    {
        name: Language_1.LanguageCode.Dansk.toUpperCase(),
        code: Language_1.LanguageCode.Dansk,
        text: Language_1.Languages.Dansk,
        numberPrefix: '+45',
    },
    {
        name: Language_1.LanguageCode.Suomi.toUpperCase(),
        code: Language_1.LanguageCode.Suomi,
        text: Language_1.Languages.Suomi,
        numberPrefix: '+358',
    },
    {
        name: Language_1.LanguageCode.Norsk.toUpperCase(),
        code: Language_1.LanguageCode.Norsk,
        text: Language_1.Languages.Norsk,
        numberPrefix: '+47',
    },
    {
        name: Language_1.LanguageCode.English.toUpperCase(),
        code: Language_1.LanguageCode.English,
        text: Language_1.Languages.English,
        numberPrefix: '',
    },
];
